import React from "react";

import Dog from "./Dog";
import {Link} from "react-router-dom";

import "./HatList.css";
import {kobbelNames, getGfx} from "../helpers";
//qwerTe11*a

export default class UserViewer extends React.Component {
    state = {
        info: false,
        hatHistory: false,
        kbHistory: [],
        otherDogs: [],

        currentBreaking: Date.now() - (1000 * 60 * 60 * 24 * 7),
    };

    kbChart = false;
    app;

    componentDidUpdate(prevProps, prevState)
    {
        if(prevProps.match.params.id !== this.props.match.params.id)
        {
            this.setState({
                currentBreaking: Date.now() - (1000 * 60 * 60 * 24 * 7),
            })
            this.componentWillMount();
        }else if(this.state.currentBreaking !== prevState.currentBreaking)
        {
            this.createGraphs();
        }
    }

    async componentWillMount()
    {
        const target = this.props.match.params.id;
        const user = await this.props.api("getUser/" + target);
        if(!user.error)
        {
            const dat = user.data;

            this.setState({
                info: dat.info[0],
                kbHistory: dat.kbHistory,
                hatHistory: dat.hatHistory,
                otherDogs: dat.otherDogs,
            });
            requestAnimationFrame(() => this.createGraphs());
            if(!this.app)
            {
                this.app = new window.PIXI.Application({
                    width: 84, 
                    height: 53, 
                    transparent: true, 
                    resolution: 1
                });
            }
            for (var i = this.app.stage.children.length - 1; i >= 0; i--) {	this.app.stage.removeChild(this.app.stage.children[i]);};
            
    
            this.refs.dog.appendChild(this.app.view);
            const data = dat.info[0];
            const dog = new Dog({
                ...data,
                "color": data.color,
                "shadowcolor": data.shadowcolor,

            }, this.props.items);
    
            this.app.stage.addChild(dog);
        }
    }


    createGraphs()
    {
        const week = new Date(Number(this.state.currentBreaking)).getTime(); //Date.now() - (1000 * 60 * 60 * 24 * 7)
        const history = [...this.state.kbHistory].reverse().filter(data => (data.unix >= week));

        if(this.kbChart)
        {
            this.kbChart.clear();
            this.kbChart.destroy();
        }
        if(!this.refs.kbHistory)
        {
            return;
        }
        const ctx = this.refs.kbHistory.getContext('2d');
		this.kbChart = new window.Chart(ctx, {
			type: 'line',
			data: {
				labels: history.map(d => new Date(Number(d.unix)).toLocaleString()),
				datasets: [{
					label: 'KB',
					backgroundColor: "#f30707",
					borderColor: "#ad2727",
					data: history.map(d => d.amount),
					fill: false,
				}]
			},
			options: {
                animation: {
                    duration: 0
                },
				responsive: true,
				title: {
					display: true,
					text: 'KB historik'
				},
				tooltips: {
					mode: 'index',
					intersect: false,
				},
				hover: {
					mode: 'nearest',
					intersect: true
				},
				scales: {
					xAxes: [{
						display: true,
						scaleLabel: {
							display: true,
							labelString: 'Tidspunkt'
						}
					}],
					yAxes: [{
						display: true,
						scaleLabel: {
							display: true,
							labelString: 'Value'
						}
					}]
				}
			}
		});
    }

    saveNotes = (e) => {
        const value = e.target.value;
        this.props.api("saveNotes/" + this.props.match.params.id, "POST", {
            data: value,
        });
    }

    render(){
        const info = this.state.info;
        const kbHistory = this.state.kbHistory;
        const hatHistory = this.state.hatHistory;

        const firstKB = kbHistory.length > 0 ? Number(kbHistory[kbHistory.length - 1].unix) : 0;
        let lastKB = 0;
        if(kbHistory.length > 0)
        {
            if(kbHistory.length > 1)
            {
                lastKB = Number(kbHistory[1].unix);
            }else{
                lastKB = Number(kbHistory[0].unix);
            }
        }
        return (<div className="info">
            {
                info && <div className="details">
                    <div className="img" ref="dog"></div>
                    <div className="name">
                        {info.name}#{info.id} [{info.username}] <img src={getGfx(`ui/flagg-${info.lang}.png`)} />
                    </div>

                    <div className="kobbel">
                        <img src={getGfx(`ui/kobbel/${kobbelNames[info.kobbel]}_kobbel.png`)} />
                    </div>
                    {kobbelNames[info.kobbel]} - Login {new Date(Number(info.lastLogin)).toLocaleString()} - Oprettet d. {new Date(Number(info.created)).toLocaleDateString()} - {info.lifepoints} life - {info.skills.split(", ").length} evner
                </div>
            }

            <h2>Noter</h2>
            <textarea key={info && info.notes ? info.notes : ""} style={{width: "500px", height: "100px"}} onBlur={(e) => this.saveNotes(e)}>{info && info.notes ? info.notes : ""}</textarea>
            <h2>Andre hunde</h2>
            <table>
                {
                    this.state.otherDogs.map((dog, key) => <tr key={key}>
                    <td>
                        <Link to={`/user/${dog.id}`}>
                            {dog.name}
                        </Link>
                    </td>
                </tr>)
                }
            </table>

            <h2 className="mt-3">Hatte historik</h2>
            <table>
                <tr style={{fontWeight: "bold"}}>
                    <td>
                        Hatte
                    </td>
                    <td>
                        Tidspunkt
                    </td>
                </tr>
                {
                    (hatHistory || []).map((hats, key) => <tr key={key}>
                    <td>
                    

                        {hats.filter(d => d.hatID !== 0).map(d => {
                            const hat = this.props.items[d.hatID];
                            if(!hat)
                            {
                                return d.hatID;
                            }
                            const IDs = hat.g.split(",");

                            return <Link to={`/view/${d.hatID}`}>
                                <img src={getGfx(`item/${IDs[0]}.png`)} />
                            </Link>;
                        })}
                    </td>
                    <td>
                        {hats.length > 0 && new Date(Number(hats[0].unix)).toLocaleString()}
                    </td>
                </tr>)
                }
            </table>
            <h2 className="mt-3">KB historik</h2>
            <div style={{width: "100%", marginBottom: "20px"}}>
                <canvas ref="kbHistory" style={{width: "100%"}}></canvas>
            <input
                type="range"
                min={firstKB}
                max={lastKB - 1}
                // step={(1000 * 60 * 60 * 24)}
                defaultValue={this.state.currentBreaking}
                style={{width: "100%"}}
                onChange={(e) => this.setState({
                    currentBreaking: e.target.value
                })}
            />
            </div>
            {/*  */}
            <table>
                <tr style={{fontWeight: "bold"}}>
                    <td>
                        Antal
                    </td>
                    <td>
                        Tidspunkt
                    </td>
                </tr>
                {
                    (kbHistory || []).map((d, key) => <tr key={key}>
                    <td>
                        {d.amount}
                    </td>
                    <td>
                        {new Date(Number(d.unix)).toLocaleString()}
                    </td>
                </tr>)
                }
            </table>

        </div>);
        return (<div className="items">
            <div className="item">
                <div>
                   
                </div>
                <div className="name">
                    Navn
                </div>
                <div className="count">
                    Antal i parken
                </div>
                <div className="date">
                    Sidste salgsdato
                </div>
            </div>
            {
                this.state.items.map(item => {
                    const IDs = item.g.split(",");
                    return (<div className="item">
                            <div className="img">
                                {/* <img src={`gfx/item/${IDs[0]}.png`} />  */}
                                <img src={`https://hundeparken.net/h5/gfx/item/${IDs[0]}.png`} /> 
                            </div>
                            <div className="name">
                                {item.id}
                            </div>
                            <div className="name">
                                {item.n}
                            </div>
                            <div className="count">
                                {item.count}
                            </div>
                            <div className="date">
                                0/0/0000
                            </div>
                    </div>);
                })
            }
        </div>);
    }
}