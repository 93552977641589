import React from "react";

import {Link} from "react-router-dom";

import "./HatList.css";
import {dataSort, formatNumber} from "../helpers";


export default class Users extends React.Component {
    state = {  
        sortBy: "kb",
        type: "DESC",

        search: "",
    };

    componentDidMount()
    {
        this.refs.search.focus();
    }

    setSort(key)
    {
        this.setState({
            sortBy: key,
            type: this.state.sortBy === key ? (this.state.type === "ASC" ? "DESC" : "ASC") : "ASC",
        });
    }

    render(){
        const users = this.props.users.map(user => ({
            ...user,
            created: Number(user.created),
            skillAmount: (user.skills || "").split(", ").length,
        }));
        const rawData = dataSort(users, this.state.sortBy, this.state.type).filter(d => this.state.search.length === 0 || d.name.toLowerCase().includes(this.state.search) || d.username.toLowerCase().includes(this.state.search));
        const PAGE_SIZE = 4000;
        const data = rawData.filter((d, i) => i < PAGE_SIZE);
        return (<div className="info">
            <h2>Hunde</h2>

            <div className="d-flex justify-content-between align-items-center">
                <div>
                    Viser <b>{Math.min(data.length, PAGE_SIZE)}</b> ud af <b>{rawData.length}</b>.
                </div>
                <input ref="search" class="form-control w-25" placeholder="Søg" onKeyUp={(e) => this.setState({
                    search: e.target.value.toLowerCase(),
                })}/>
            </div>
            <table style={{width: "100%"}}>
                <tr style={{fontWeight: "bolder", cursor: "pointer"}}>
                    <td></td>
                    <td onClick={() => this.setSort("id")}>ID</td>
                    <td onClick={() => this.setSort("name")}>Navn</td>
                    <td onClick={() => this.setSort("username")}>Brugernavn</td>
                    <td onClick={() => this.setSort("kb")}>KB</td>
                    <td onClick={() => this.setSort("skillAmount")}>Evner</td>
                    <td onClick={() => this.setSort("admin")}>Admin</td>
                    <td onClick={() => this.setSort("moderator")}>Mod</td>
                    <td onClick={() => this.setSort("created")}>Oprettet</td>
                </tr>
                {
                    data.map((user, key) => 
                        <tr key={key} style={{cursor: "pointer"}} onClick={() => this.props.history.push("/user/" + user.id)}>
                            <td>#{key + 1}</td>
                            <td>{user.id}</td>
                            <td style={user.lifepoints <= 0 ? {color: "red"} : {}}>
                                {user.lifepoints <= 0 && "💀"}
                                {user.name}
                                {user.lifepoints <= 0 && "💀"}
                            </td>
                            <td>{user.username}</td>
                            <td>{formatNumber(user.kb)}</td>
                            <td>{user.skillAmount}</td>
                            <td>{user.admin}</td>
                            <td>{user.moderator}</td>
                            <td>{new Date(Number(user.created)).toLocaleDateString()}</td>
                        </tr>)
                }
            </table>
        </div>);
    }
}