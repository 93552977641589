import React from "react";
import TagManager from 'react-gtm-module'

// import AllItems from "./AllItems.js";
// import './App.css';
import Items from "./components/Items";
import Users from "./components/Users";
import HatAnimator from "./components/HatAnimator";
import HatList from "./components/HatList";
import HatViewer from "./components/HatViewer";
import UserViewer from "./components/UserViewer";
import Stats from "./components/Stats";
import AnalyzeDig from "./components/AnalyzeDig";
import ShopViewer from "./components/ShopViewer";
import HatCalulator from "./components/HatCalulator";
import NavBar from "./components/NavBar";
import HatCount from "./HatCount";
import HatEditor from "./components/HatEditor";
import HatRestocks from "./components/HatRestocks";
import HatRestocksOld from "./components/HatRestocksOld";
import Bots from "./components/Bots";
// import {ClientJS} from './client.min.js';

import {api as appi} from "./helpers";

import {
    Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";
  import { createBrowserHistory } from "history";

const history = createBrowserHistory();
history.listen(() => {
    window.scrollTo(0, 0);
});


let lastPage = window.location.pathname + window.location.search;
// ReactGA.pageview(lastPage);
TagManager.initialize({
  gtmId: 'G-TJ3L69T76M'
});

export default class AppContainer extends React.Component {
    state = {
        items: [],
        allItems: [],
        users: [],
        itemCount: [],
        rarities: [],
        key: false,
    };

    async getItems()
    {
      const data = await this.api("items");
      // console.log("Items", data);

      this.setState({
        items: data.data,
        allItems: data.data.map(item => ({
            ...item,
            count: 0
        }))
      });
    }

    async nextItem()
    {
      const data = (await this.api("hatRarities")).data;
      const next = this.state.items.find(d => d && !data.some(hat => hat.hatID === d.id));
      this.setState({
        rarities: data,
      });
      return next;
    }

    componentDidUpdate()
    {
      let currPage = window.location.pathname + window.location.search;
      if(lastPage !== currPage)
      {
        console.log("New view", currPage, lastPage);
        lastPage = currPage;
        // ReactGA.pageview(lastPage);
      }
    }

    componentDidMount(){
      const key = localStorage.getItem("keyzz");
      if(key)
      {
        this.setLogin(key);
      }
      requestAnimationFrame(() => this.getItems(key));
    }

    setLogin(key)
    {
      if(!key)
      {
        window.localStorage.removeItem("keyzz");
      }else{
        window.localStorage.setItem("keyzz", key);
      }
      this.setState({
        key
      });
      setTimeout(() => this.getData(), 500);
    }

    api = (endpoint, type = "GET", data = null, server = undefined) =>
    {
      return appi(endpoint, type, data, this.state.key, server);
    }

    async getData()
    {
      this.nextItem();

        const [itemCount, users] = await Promise.all([
            this.api("hatAmount"),
            this.api("users"),
        ]).then(d => d);

        if(itemCount && users)
        {
          this.setState({
            itemCount,
            users
          });
        }
    }

    render(){
      console.log("Sup", process.env);
        // const validItems = ;//.map((d, i) => ({...d, i})).filter(item => item.u >= 1 && item.u <= 3);
        if(!this.state.key)
        {
          return (<form onSubmit={(e) => {
            e.preventDefault();
            this.setLogin(this.refs.loginPass.value);
            setTimeout(() => window.location.reload(), 2500);
          }}>
            <label htmlFor="pass">
              Kodeord [TEST-1337]
            </label><br/>
            <input type="password" ref="loginPass" autoComplete="off" id="pass"/><br/>
            <button type="submit">Login</button>
          </form>);
        }
        if(this.state.items.length === 0)
        {
          return (null);
        }
        return (<Router history={history}>
            <NavBar signOut={() => this.setLogin(false)}/>
            <div>
              <Switch>
                <Route path="/user/:id">
                  {(props) =>  <UserViewer items={this.state.allItems} itemCount={this.state.itemCount} users={this.state.users} {...props} api={this.api} history={history} />}
                </Route>
                <Route path="/view/:id">
                  {(props) =>  <HatViewer items={this.state.allItems} itemCount={this.state.itemCount} users={this.state.users} {...props} api={this.api} history={history} />}
                </Route>
                <Route path="/edit/:id">
                  {(props) =>  <HatEditor items={this.state.allItems} nextItem={() => this.nextItem()} itemCount={this.state.itemCount} users={this.state.users} {...props} api={this.api} history={history} />}
                </Route>
                <Route path="/restocks">
                  {(props) =>  <HatRestocks items={this.state.allItems} rarities={this.state.rarities} nextItem={() => this.nextItem()} itemCount={this.state.itemCount} users={this.state.users} {...props} api={this.api} history={history} />}
                </Route>
                <Route path="/restocksOld">
                  {(props) =>  <HatRestocksOld items={this.state.allItems} rarities={this.state.rarities} nextItem={() => this.nextItem()} itemCount={this.state.itemCount} users={this.state.users} {...props} api={this.api} history={history} />}
                </Route>
                <Route path="/shop/:id">
                  {(props) =>  <ShopViewer items={this.state.allItems} itemCount={this.state.itemCount} users={this.state.users} {...props} api={this.api} history={history} />}
                </Route>
                <Route path="/calc">
                    <HatCalulator items={this.state.allItems} itemCount={this.state.itemCount}  users={this.state.users} history={history}/>
                </Route>
                <Route path="/users">
                    <Users users={this.state.users} history={history}/>
                </Route>
                <Route path="/items">
                    <Items items={this.state.items} allItems={this.state.allItems} itemCount={this.state.itemCount} history={history}/>
                </Route>
                <Route path="/stats">
                  <Stats items={this.state.allItems} itemCount={this.state.itemCount} users={this.state.users} api={this.api} history={history}/>
                </Route>
                <Route path="/bots">
                  <Bots api={this.api} />
                </Route>
                <Route path="/animator">
                  <HatAnimator />
                </Route>
                <Route path="/analyzeDig">
                  <AnalyzeDig api={this.api} history={history} />
                </Route>
                <Route path="/">
                  <HatList items={this.state.allItems} itemCount={this.state.itemCount} users={this.state.users} api={this.api} history={history} />
                </Route>
              </Switch>
            </div>
          </Router>);
       
    }
}