import React from "react";
import "./HatList.css";
import {dataSort, getGfx, shops as shopNames, download, cryptoRand} from "../helpers";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";

// import Restocks from "./Restock.js";

export default class HatRestocks extends React.Component {
    state = {
        count: {},
        items: [],

        sortBy: "amount",
        type: "ASC",
        search: "",

        generateHats: false,
        showGroups: false,
        showDontSell: false,
        editting: false,
    };

    componentWillMount()
    {
        this.parseData();
    }

    componentDidMount()
    {
        if(this.refs.search)
        {
            this.refs.search.focus();
        }
    }

    componentDidUpdate(prevProps, prevState)
    {
        if(prevProps.itemCount.length !== this.props.itemCount.length)
        {
            this.parseData();
        }
    }

    round5(x)
    {
        return Math.ceil(x/5)*5;
    }

    randBetween(from, to)
    {
        return Math.round(cryptoRand() * (to - from)) + from;
    }

    getPrice(price, difference = 0.3) {
        const prob = this.randBetween(1 - difference, difference + 1);//1 - Math.abs((cryptoRand() * (difference * 2)) - difference);

        return this.round5(price * prob);
    }

    parseData()
    {
        const d = this.props.itemCount;
        const counter = d.reduce((arr, curr) => {
            arr[curr.hatID] = curr;
            return arr;
        }, []);
        this.setState({
            items: this.props.items.map((item, key) => {
                const itemCount = this.props.itemCount.find(d => d.hatID === item.id);
                const rarity = this.props.rarities.find(d => d.hatID === item.id);
                return {
                    ...item,
                    amount: itemCount ? itemCount.amount : 0,
                    deadAmount: itemCount ? itemCount.deadAmount : 0,
                    lastPrice: itemCount ? itemCount.lastSold : 0,
                    rarity, 
                };
            }).filter(d => !!d.amount && !!d.rarity && d.rarity.rarity !== -1).map(d => {

                const [from, to] = this.calcRarity(d.amount, d.rarity.rarity, d.rarity.sentimental);
                return {
                    ...d,
                    restockFrom: from,
                    restockTo: to, 
                };
            })
        })
    }

    setSort(key)
    {
        this.setState({
            sortBy: key,
            type: this.state.sortBy === key ? (this.state.type === "ASC" ? "DESC" : "ASC") : "ASC",
        });
    }

    otherTypes = ["Normal", "Flower", "Fugl", "Dildo", "Jul", "Animeret kobbel", "Sommer", "Haloween", "Præmie"];
    groupNames = ["Dont sell", "Common", "Semi-uncommon", "Uncommon", "Semi-rare", "Rare", "Semi-super rare", "Super rare", "Sentimental"];
    groups = {
        // 6: [1, 6],  // Super rare
        // 5: [5,  8], // Semi-super-rare
        // 4: [5, 12], // Rare
        // 3: [10, 15], // Semi-rare
        // 2: [13, 20], // Uncommon
        // 1: [15, 20], // Semi-uncommon
        // 0: [20, 150], // Common


        0: [9999999,999999999],
        1: [80, 999], // Common
        2: [35, 79], // Semi-uncommon
        3: [18, 34], // Uncommon
        4: [10, 17], // Semi-rare
        5: [6, 9], // Rare
        6: [4,  5], // Semi-super-rare
        7: [0, 4],  // Super rare
        8: [14, 20], // Sentimental
        "sentimental": [14, 20], // Sentimental
    };
    restockDiff = 0.3; // 30%

    calcRarity(amount, rarity, isSentimental)
    {
        return [0,0];
        const [from, to] = this.groups[isSentimental ? "sentimental" : rarity];

        return [
            from - amount,
            to - amount,
        ];
    }

    getRarity(amount, debug = false)
    {
        const target = Object.keys(this.groups).map(i => {
            const curr = this.groups[i];
            return {
                i,
                diffA: amount - curr[0],
                diffB: amount - curr[1],
                match: curr[0] <= amount && amount <= curr[1],
            };
            //curr[0] <= amount && amount <= curr[1]
        }).filter(d => d.match && d.i !== "sentimental" && d.i !== "0" && d.i !== "8").sort((a, b) => b.diffB - a.diffB).sort((a, b) => b.diffA - a.diffA);
        if(debug)
        {
            // console.log("DEBUG", target, amount);
        }
        // const target = Object.keys(this.groups).find(i => {
        //     const curr = this.groups[i];
        //     return curr[0] <= amount && amount <= curr[1];
        // });

        return target[0] ? Number(target[0].i) : 1;
    }

    formatTime(unix)
    {
        const zeroPad = val => val < 10 ? `0${val}` : val;

        const temp = new Date(unix);
        return `${(temp.getFullYear())}-${zeroPad(temp.getMonth() + 1)}-${zeroPad(temp.getDate())}T${zeroPad(temp.getHours())}:${zeroPad(temp.getMinutes())}`;
        // return `${zeroPad(temp.getDate())}.${zeroPad(temp.getMonth() + 1)}.${temp.getFullYear()} ${zeroPad(temp.getHours())}:${zeroPad(temp.getSeconds())}`
    }

    shuffle(array) {
        let currentIndex = array.length,  randomIndex;
      
        // While there remain elements to shuffle...
        while (currentIndex != 0) {
      
          // Pick a remaining element...
          randomIndex = Math.floor(cryptoRand() * currentIndex);
          currentIndex--;
      
          // And swap it with the current element.
          [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
        }
      
        return array;
      }


    _daysOffset = 0;
    _shops = [];
    getCSV()
    {
        let output = [
            ["Shop", "Hat", "Pris", "Beskrivelse", "Antal", "Start", "Stop"],
        ];

        let today = new Date();
        today.setDate(today.getDate() + this._daysOffset);

        let hats = this.shuffle(this._shops.map(shop => {
            return shop.hats.map(hat => ({
                ...hat,
                shopID: shop.id,
            }))
        }).flat());

        const days = 9;
        let perDay = hats.length / days; 
        console.log(hats, perDay);
        let offset = 0;
        for(let i = 0; i < days; i++)
        {

            let end = new Date();
            end.setDate(today.getDate() + this._shops.length);

            let todayAmount = i === (days - 1) ? Math.ceil(perDay) : Math.floor(perDay);
            for(let j = 0; j < todayAmount; j++)
            {
                const hat = hats[offset + j];
                console.log(i, hat);
                today.setHours(Math.floor(cryptoRand() * 14) + 8);
                end.setHours(today.getHours());

                let shortEnd = new Date();
                shortEnd.setDate(today.getDate() + 2);
                shortEnd.setHours(today.getHours());

                output.push([hat.shopID, hat.info.id, hat.price, hat.info.d, hat.amount, this.formatTime(today), this.formatTime(hat.rarity >= 5 ? shortEnd : end)]);
            }
            today.setDate(today.getDate() + 1);
            offset += todayAmount;
        }

        // for(let i = 0; i < hats.length; i++)
        // {
        //     const hat = hats[i];
        //     console.log(shop);
            
        //     today.setHours(Math.floor(cryptoRand() * 14) + 8);
            
        //     let end = new Date();
        //     end.setDate(today.getDate() + this._shops.length);
        //     end.setHours(today.getHours());

        //     let shortEnd = new Date();
        //     shortEnd.setDate(today.getDate() + 2);
        //     shortEnd.setHours(today.getHours());
        //     for(let j = 0; j < shop.hats.length; j++)
        //     {
        //         const hat = shop.hats[j];
        //         output.push([shop.id, hat.info.id, hat.price, hat.info.d, hat.amount, this.formatTime(today), this.formatTime(hat.rarity >= 5 ? shortEnd : end)]);
        //     }
        //     today.setDate(today.getDate() + 1);
        // }


        let csvContent = "data:text/csv;charset=utf-8," + output.map(e => e.join(";")).join("\n");
        download("Shops.csv", csvContent);
    }

    render(){
        // const rawData = dataSort(this.state.items, this.state.sortBy, this.state.type).filter(d => this.state.search.length === 0 || d.n.toLowerCase().includes(this.state.search));
        // const data = rawData.filter((d, i) => i < 1000);

        // const data = this.props.rarities.map(d => ({
        //     ...d,
        //     info: this.props.items[d.hatID],
        // }));

        
        const data = this.props.items.map((item, key) => {
            const itemCount = this.props.itemCount.find(d => d.hatID === item.id);
            return {
                ...item,
                amount: itemCount ? itemCount.amount : 0,
                deadAmount: itemCount ? itemCount.deadAmount : 0,
                lastSold: itemCount ? itemCount.lastSold : null,
                lastPrice: itemCount ? itemCount.lastPrice : null,
            };
        }).map((info, key) => ({
            info,
            ...this.props.rarities.find(dat => dat.hatID === info.id)
        }));
        const SELLABLE_HATS = data.filter(d => d?.rarity !== 0);
        if(this.state.generateHats)
        {
            // const temp = new Restocks(SELLABLE_HATS);
            // console.log("Temp", temp);
            // return (null);

            const shopTargets = {

                // Henke
                "11": [7, 9],

                // Flower
                "8": [0, 0],

                // Gøgler shop
                "15": [0, 0],

                
                // Kobbels
                "4": [3, 4],
                "5": [3, 4],
                "6": [3, 4],
                "7": [3, 4],
                "9": [3, 4],
                "10": [3, 4],
            };
            /*
                0: [9999999,999999999],
                1: [80, 999], // Common
                2: [35, 79], // Semi-uncommon
                3: [18, 34], // Uncommon
                4: [10, 17], // Semi-rare
                5: [6, 9], // Rare
                6: [4,  5], // Semi-super-rare
                7: [0, 4],  // Super rare
                8: [14, 20], // Sentimental
            */
            let dropRate = {
                1: 0.3, // Common
                2: 0.25, // Semi-uncommon
                3: 0.17, // Uncommon
                4: 0.17, // Semi-rare
                5: 0.065, // Rare
                6: 0.025, // Semi super-rare
                7: 0.015, // Super rare
                8: 0.005, // Sentimental
            };

            // function chanceOfRoll(times = 26)
            // {
            //     let out = {};
            //     for(let i = 1; i < 9; i++)
            //     {
            //         const key = groupNames[i];
            //         out[key] = 0;
            //         for(let t = 0; t < times; t++)
            //         {
            //             out[key] += dropRate[i];//(1 - dropRate[i]);
            //         }
            //         out[key] = Math.min(out[key], 1) * 100;
            //     }
            //     return out;
            // }
            const groupPrices = {
                1: 800, // Common
                2: 1_500, // Semi-uncommon
                3: 4_000, // Uncommon
                4: 7_000, // Semi-rare
                5: 20_000, // Rare
                6: 25_000, // Semi super-rare
                7: 25_000, // Super rare
                8: 20_000, // Sentimental
            };
            const groupMins = {
                1: 8, // Common
                2: 8, // Semi-uncommon
                3: 3, // Uncommon
                4: 2, // Semi-rare
                5: 2, // Rare
                6: 2, // Semi super-rare
                7: 2, // Super rare
                8: 2, // Sentimental
            };

            const allSellableHats = SELLABLE_HATS.filter(item => item.info.g).filter(item => {
                const groupInfo = this.groups[item.rarity];
                if(!groupInfo)
                {
                    return false;
                }
                const diff = 1 - (item.info.amount / groupInfo[1]);

                return diff >= this.restockDiff;// && item.restockTo > 0;
            });


            let hatsSold = [];
            const sellableHats = allSellableHats.filter(d => d?.info?.amount > 0 && ((d?.info.lastSold && d?.info?.lastPrice > 0) || d.rarity <= 4)).reduce((arr, curr) => {
                if(!arr[curr.rarity])
                {
                    arr[curr.rarity] = [];
                }
                arr[curr.rarity].push(curr);
                hatsSold.push(curr);
                return arr;
            }, []);//.filter(d => (d.rarity === i));
            // console.clear();
            console.log("Sellable", hatsSold);

            let buildDropTable = () => {
                let out = [];
                let lastNumber = 0;
                for(let i = 1; i <= 8; i++)
                {
                    out.push({
                        from: lastNumber,
                        to: lastNumber + dropRate[i],
                        i,
                    });
                    lastNumber += dropRate[i];
                }
                return out;
            };

            let getDrop = () => {
                const table = buildDropTable();
                const val = cryptoRand();
                let target = table.find(drop => drop.from < val && drop.to >= val);
                if(!target)
                {
                    console.clear();
                }
                return target.i;
            };

            let totalHats = 0;
            let shops = Object.keys(shopTargets).map(id => {
                const [from, to] = shopTargets[id];
                const count = this.randBetween(from, to);//Math.round(cryptoRand() * (to - from)) + from;
                totalHats += count;
                return {
                    id,
                    count,
                    hats: [],
                };
            });
            let tempHats = [];
            let hats = [...Array(totalHats)].map(d => getDrop()).map((d, i, arr) => {
                const target = sellableHats[d] ?? sellableHats[1];
                let out;
                
                while(!out || tempHats.includes(out.hatID))
                {
                    out = target[Math.floor(cryptoRand() * target.length)];
                }
                tempHats.push(out.hatID);
                return out;
            });
            // let backupHats = [...Array(totalHats)].map(d => getDrop()).map(d => {
            //     const target = sellableHats[d] ?? sellableHats[1];
            //     return  target[Math.floor(cryptoRand() * target.length)];
            // });
            let hatsLeft = [];

            if(1) // Add new hat
            {
                const unsoldHats = allSellableHats.filter(hat => hat?.info?.amount === 0 || !hatsSold.some(d => d.hatID === hat.hatID));//allSellableHats.filter(d => d?.info?.amount === 0);
                console.log("Unsold hats", unsoldHats);
                if(unsoldHats.length > 0)
                {
                    const NEW_HAT = unsoldHats[Math.floor(cryptoRand() * unsoldHats.length)];
                    hats[Math.floor(cryptoRand() * hats.length)] = NEW_HAT;
                }
            }

            for(let i = 0; i < hats.length; i++)
            {
                let hat = {...hats[i]};

                const groupInfo = this.groups[hat.rarity];
                const restockUpTo = groupInfo[1] - hat.info.amount;
                const neverRestocked = hat.info.amount === 0 || !hat.info.lastPrice;

                // //Figure out price and amount
                if(neverRestocked)
                {
                    hat.price = this.getPrice(groupPrices[hat.rarity]);
                    hat.amount = Math.max(Math.round((restockUpTo * 0.3)), 6);

                    if(hat.price > 18_000 && Math.floor(cryptoRand() * 100) <= 9)
                    {
                        hat.price = this.getPrice(4000);
                        console.log("Kaching! Cheaper hat!!");
                    }
                }else{
                    hat.price = this.getPrice(hat.info.lastPrice);//((cryptoRand() * 0.5) + 0.75)); // 0.75 - 1.25 price
                    hat.amount = Math.min(Math.max(Math.round(cryptoRand() * (restockUpTo * 0.3)), 1), 13);
                }

                hat.amount = Math.max(hat.amount, groupMins[hat.rarity] ?? 0);

                let targetShop = -1;
                if(hat?.info?.u === 3)
                {
                    targetShop = shops.findIndex(d => d.id === "15");
                }else if(this.otherTypes[hat.otherType] === "Flower")
                {
                    targetShop = shops.findIndex(d => d.id === "8");
                }
                
                if(targetShop !== -1)
                {
                    shops[targetShop].hats.push(hat);
                    // hats = hats.filter((h, key) => key !== i);
                }else{
                    hatsLeft.push(hat);
                }
            }
            
            // console.clear();
            for(let i = 0; i < shops.length; i++)
            {
                const shop = shops[i];
                if(shop.count > 0)
                {
                    while(shops[i].hats.length < shop.count && hatsLeft.length > 0)
                    {
                        // shops[i].hats.push(hatsLeft.pop());
                        let temp = hatsLeft.pop();
                        const equalCheck = shops[i].hats.some(hat => hat.hatID === temp.hatID);
                        if(hatsLeft.length > 0 && equalCheck)
                        {
                            console.log("Found equals!!", temp);
                            const shopIndex = shops.findIndex(shop => shop.count > 0 && shop.hats.length > 0);
                            if(shopIndex !== -1) // Replace hat with a hat from another shop
                            {
                                shops[i].hats.push(shops[shopIndex].hats[0]);
                                shops[shopIndex].hats[0] = temp;
                            }else{ // Must be first shop, so fallback to this method
                                shops[i].hats.push(hatsLeft.pop());
                                hatsLeft.push(temp);
                            }
                        }else{
                            shops[i].hats.push(temp);
                        }
                    }
                }
            }

            shops = shops.map(shop => ({
                ...shop,
                hats: shop.hats.filter((hat, i, arr) => arr.findIndex(d => d.hatID === hat.hatID) === i)
            }));
            this._shops = shops;
            console.log("Hmm", shops);
            
            return (<div className="items">
                    <button onClick={() => this.setState({generateHats: false})}>Tilbage</button>
                    <input type="number" min="0" onChange={v => this._daysOffset = Number(v.target.value)} placeholder="Dage i fremtiden" style={{marginLeft: "20px", width: "150px"}}/>
                    <button onClick={() => this.getCSV()}>Hent CSV</button>

                    {
                        shops.map((shop, key) => <div key={key}>
                                <h3>{shopNames[shop.id] ?? shop.id}</h3>
                                <table style={{width: "80%"}}>
                                    <tr>
                                        <td>
                                        
                                        </td>
                                        <td>
                                        
                                        </td>
                                        <td>
                                            Sidste pris
                                        </td>
                                        <td>
                                            Navn
                                        </td>
                                        <td>
                                         Antal
                                        </td>
                                        <td>
                                        Pris
                                        </td>
                                        <td>
                                        
                                        </td>
                                        <td>
                                        
                                        </td>
                                    </tr>
                                    {
                                        shop.hats.filter((val, key, arr) => {
                                            if(arr.indexOf(val) === key)
                                            {
                                                return true;
                                            }
                                            console.log("DUPLICATE HATS", val);
                                            return false;
                                        }).map(hat => {
                                            const IDs = ((hat.info.g || "").split(","));
                                            if(!hat.amount || !hat.price)
                                            {
                                                console.log("HUH", hat);
                                            }
                                            return (<tr>
                                                <td style={{textAlign: "center"}}>
                                                    <img src={getGfx(`item/${IDs[0]}.png`)} style={{maxWidth: "50px", maxHeight: "50px"}} loading="lazy" /> 
                                                </td>
                                                <td>
                                                    {hat.hatID}
                                                </td>
                                                <td>
                                                    {hat.info.lastPrice && hat.info.lastPrice + "kb"}
                                                </td>
                                                {/* <td>
                                                    {hat.otherType}
                                                </td> */}
                                                <td>
                                                    {hat.info.n}
                                                </td>
                                                <td>
                                                    {hat.amount || "UNKWOWN"}
                                                </td>
                                                <td>
                                                    {hat.price || "UNKOWN"}
                                                </td>
                                                <td>
                                                    {this.groupNames[hat.rarity]}
                                                </td>
                                            </tr>);
                                        })
                                    }
                                </table>
                            </div>)
                    }


                    {/* <h1>All hats</h1>
                    <table style={{width: "80%"}}>
                        <tr>
                            <td>
                            
                            </td>
                            <td>
                                Navn
                            </td>
                        </tr>
                        {
                            hats.map(hat => {
                                const IDs = ((hat.info.g || "").split(","));
                                return (<tr>
                                    <td style={{textAlign: "center"}}>
                                        <img src={getGfx(`item/${IDs[0]}.png`)} style={{maxWidth: "50px", maxHeight: "50px"}} loading="lazy" /> 
                                    </td>
                                    <td>
                                        {hat.hatID}
                                    </td>
                                    <td>
                                        {hat.info.n}
                                    </td>
                                    <td>
                                        {this.groupNames[hat.rarity]}
                                    </td>
                                </tr>);
                            })
                        }
                    </table> */}
                    
                </div>);
        }
        if(this.state.editting)
        {
            return (<div className="items">
                
                <button onClick={() => this.setState({editting: false})}>Tilbage</button>
            <h1>Hatte grupper ({SELLABLE_HATS.length})</h1>
            {
                this.state.showGroups ? <table style={{width: "100%"}}>
                <tr style={{fontWeight: "bolder"}}>
                    <td>
                        
                    </td>
                    <td>
                        Navn
                    </td>
                    <td>
                        Antal
                    </td>
                    <td>
                        Gruppe
                    </td>
                    <td>
                        Type
                    </td>
                </tr>
                {
                    SELLABLE_HATS.sort((a,b) => (a.rarity ?? -1) - (b.rarity ?? -1)).map((hat, key) => {
                        const IDs = ((hat.info.g || "").split(","));
                        return (<tr key={key}>
                                <td style={{textAlign: "center"}}>
                                    <img src={getGfx(`item/${IDs[0]}.png`)} style={{maxWidth: "50px", maxHeight: "50px"}} loading="lazy" /> 
                                </td>
                                <td>
                                    {hat.info.n}
                                </td>
                                <td>
                                    {hat.info.amount}
                                </td>
                                <td>
                                    {hat.rarity}
                                </td>
                                <td>
                                    <form onChange={(e) => {
                                        // console.log("Changed", e.target.value, hat);
                                        this.props.api("saveHat", "POST", {
                                            id: hat.info.id,
                                            rarity: e.target.value,//this.state.unused ? -1 : this.refs.rarity.value,
                                        });
                                    }}>
                                        {
                                            this.groupNames.map((d, k) => {
                                                return (<div key={k +""+ (hat?.rarity === k ? 1 : 0)}>
                                                    <input type="radio" name={`${key}`} id={key + "" + k} defaultChecked={hat?.rarity === k} value={k}/>
                                                    <label htmlFor={key + "" + k}>{d}</label>
                                                </div>);
                                            })
                                        }
                                    </form>
                                </td>
                                <td>
                                    <form onChange={(e) => {
                                        // console.log("Changed", e.target.value, hat);
                                        this.props.api("saveHat", "POST", {
                                            id: hat.info.id,
                                            otherType: e.target.value,//this.state.unused ? -1 : this.refs.rarity.value,
                                        });
                                    }}>
                                        {
                                            this.otherTypes.map((d, k) => {
                                                return (<div key={k +""+ (hat?.otherType === k ? 1 : 0)}>
                                                    <input type="radio" name={`${key}zz`} id={key + "-" + k} defaultChecked={hat?.otherType === k} value={k}/>
                                                    <label htmlFor={key + "-" + k}>{d}</label>
                                                </div>);
                                            })
                                        }
                                    </form>
                                </td>
                            </tr>);
                    })
                }

            </table> : <button onClick={() => this.setState({showGroups: true})}>Vis</button>
            }
                <h1>Dont sell ({data.length - SELLABLE_HATS.length})</h1>
                {
                    this.state.showDontSell ? <table style={{width: "100%"}}>
                    <tr style={{fontWeight: "bolder"}}>
                        <td>
                            
                        </td>
                        <td>
                            Navn
                        </td>
                        <td>
                            Antal
                        </td>
                        <td>
                            Gruppe
                        </td>
                    <td>
                        Type
                    </td>
                    </tr>
                    {
                        data.filter(d => d?.rarity === 0).filter((d, k) => k < 5000).map((hat, key) => {
                            const IDs = (hat.info.g.split(","));
                            return (<tr key={key}>
                                    <td style={{textAlign: "center"}}>
                                        <img src={getGfx(`item/${IDs[0]}.png`)} style={{maxWidth: "50px", maxHeight: "50px"}} loading="lazy" /> 
                                    </td>
                                    <td>
                                        {hat.info.n}
                                    </td>
                                    <td>
                                        {hat.info.count}
                                    </td>
                                    <td>
                                        <form onChange={(e) => {
                                            // console.log("Changed", e.target.value, hat);
                                            this.props.api("saveHat", "POST", {
                                                id: hat.info.id,
                                                rarity: e.target.value,//this.state.unused ? -1 : this.refs.rarity.value,
                                            });
                                        }}>
                                            {
                                                this.groupNames.map((d, k) => {
                                                    return (<div key={k}>
                                                        <input type="radio" name={`${key}`} id={key + "" + k} defaultChecked={hat?.rarity === k} value={k}/>
                                                        <label htmlFor={key + "" + k}>{d}</label>
                                                    </div>);
                                                })
                                            }
                                        </form>
                                    </td>
                                <td>
                                    <form onChange={(e) => {
                                        // console.log("Changed", e.target.value, hat);
                                        this.props.api("saveHat", "POST", {
                                            id: hat.info.id,
                                            otherType: e.target.value,//this.state.unused ? -1 : this.refs.rarity.value,
                                        });
                                    }}>
                                        {
                                            this.otherTypes.map((d, k) => {
                                                return (<div key={k +""+ (hat?.otherType === k ? 1 : 0)}>
                                                    <input type="radio" name={`${key}zz`} id={key + "-" + k} defaultChecked={hat?.otherType === k} value={k}/>
                                                    <label htmlFor={key + "-" + k}>{d}</label>
                                                </div>);
                                            })
                                        }
                                    </form>
                                </td>
                                </tr>);
                        })
                    }

                </table> : <button onClick={() => this.setState({showDontSell: true})}>Vis</button>
                }
            </div>);  
        }
        return (<div className="items">
                <button onClick={() => this.setState({editting: true})}>Edit</button>
                <button onClick={() => {
                    this.setState({generateHats: true});
                    this._daysOffset = 0;
                }}>Generate test-shop</button>
            {
                this.groupNames.map((title, i) => i > 0 && <div style={{paddingTop: "20px", paddingBottom: "5px"}}>
                    <h2>{title}</h2>

                    <table style={{width: "80%"}}>
                        <tr style={{fontWeight: "bolder"}}>
                            <td>
                                
                            </td>
                            <td>
                                Navn
                            </td>
                            <td>
                                Current rarity
                            </td>
                            <td style={{textAlign: "center"}}>
                                Current count
                            </td>
                            {/* <td>
                                Target rarity
                            </td> */}
                            <td style={{textAlign: "center"}}>
                            Restock up to
                            </td>
                        </tr>

                        {
                        data.filter(d => (d.rarity === i)).map((item, key) => {
                            const IDs = item.info.g.split(",");
                            const r = item.rarity;
                            const currentRarity = this.getRarity(item.info.amount, item.id === 827);
                            const groupInfo = this.groups[item.rarity];
                            if(!groupInfo)
                            {
                                return null;
                            }
                            const diff = 1 - (item.info.amount / groupInfo[1]);
                            const diff2 = 1 - (item.info.amount / groupInfo[0]);

                            const restockUpTo = groupInfo[1] - item.info.amount;

                            const canRestock = diff >= this.restockDiff;// && item.restockTo > 0;
                            if(item.id === 734)
                            {
                                console.log("Why ??", canRestock, diff, diff2, this.restockDiff);
                            }
                            return (<tr>
                                <td>
                                <img src={getGfx(`item/${IDs[0]}.png`)} /> 
                                </td>
                                <td>
                                    {/* {item.id} */}
                                    {item.info.n}
                                </td>
                                <td onClick={() => this.setSort("rarity")}>
                                    {this.groupNames[this.getRarity(item.info.amount)]}
                                </td>
                                {/* <td onClick={() => this.setSort("rarity")}>
                                    {this.groupNames[item.rarity]}
                                {/* {r.rarity === currentRarity ? this.groupNames[r.rarity] : <>{this.groupNames[this.getRarity(item.amount)]} / {this.groupNames[r.rarity]}</>} */}
                                {/* </td> */} 
                                <td onClick={() => this.setSort("rarity")} style={{textAlign: "center"}}>
                                    {item.info.amount}
                                </td>
                                <td onClick={() => this.setSort("restockFrom")} style={{color: canRestock ? "green" : "red", textAlign: "center"}}>
                                    {
                                        canRestock ? <>{Math.max(restockUpTo, 0)}</> : "Dont restock"
                                    }
                                     
                                </td>
                            </tr>);
                            return (<Link to={`/view/${item.id}`} key={item.id}>
                                    <div className="item">
                                        <div className="img">
                                            {/* <img src={`gfx/item/${IDs[0]}.png`} />  */}
                                            <img src={`https://hundeparken.net/h5/gfx/item/${IDs[0]}.png`} /> 
                                        </div>
                                        <div className="name">
                                            {item.n}
                                        </div>
                                        {/* <div className="count">
                                            {item.amount}
                                        </div> */}
                                        <div className="" style={{textAlign: "center", justifyContent: "center"}}>
                                        {r.rarity === currentRarity ? this.groupNames[r.rarity] : <>{this.groupNames[this.getRarity(item.amount)]} / {this.groupNames[r.rarity]}</>}
                                        </div>

                                        
                                        <div className="count">
                                            {item.restockFrom} 
                                        </div>
                                        <div className="count">
                                            {item.restockTo} 
                                        </div>

                                        {/* <div className="count">
                                            {item.amount} {item.deadAmount > 0 && (<div style={{color: "red", marginLeft: "10px"}}>(-{item.deadAmount})</div>)}
                                        </div> */}
                                        {/* <div className="date">
                                            {new Date(Number(item.lastSold || "ffs")).toLocaleDateString()}
                                        </div> */}
                                </div>
                            </Link>);
                        })
                    }
                    </table>
                    
                </div>)
            }


            
            {/* <h2>Hatte</h2>
            <div className="d-flex justify-content-between align-items-center">
                <div>
                    Viser <b>{Math.min(rawData.length, 1000)}</b> ud af <b>{data.length}</b>.
                </div>
                <input ref="search" className="form-control w-25" placeholder="Søg" onKeyUp={(e) => this.setState({
                    search: e.target.value.toLowerCase(),
                })}/>
            </div> */}
           
        </div>);
    }
}