import React from "react";

import {Link} from "react-router-dom";

export default class NavBar extends React.Component {
    
    render(){
        return (<div className="menu">
            <Link to="/">
                Hatte
            </Link>
            <Link to="/calc">
                Pris-beregner
            </Link>
            <Link to="/shop/0">
                Shops
            </Link>
            <Link to="/users">
                Hunde
            </Link>
            <Link to="/stats">
                Statistik
            </Link>
            <Link to="/animator">
                Hat-animator
            </Link>
            <Link to="/restocks">
                Restock
            </Link>
            {/* <Link to="/analyzeDig">
                Grave analyse
            </Link> */}
            <Link to="/items">
                Items
            </Link>
            <Link to="/bots">
                Bots
            </Link>
            <Link to="/" onClick={this.props.signOut}>
                Log ud
            </Link>
        </div>);
       
    }
}