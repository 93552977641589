import React from "react";


import "./Stats.css";
import {kobbelNames, formatNumber} from "../helpers";


export default class Stats extends React.Component {
    state = {  
        sortBy: "kb",
        type: "ASC",

       stats: false,

       kbHistory: false,

       onlyAfterLarsFilter: true,
    };

    colors = {"red":"rgb(255, 99, 132)","orange":"rgb(255, 159, 64)","yellow":"rgb(255, 205, 86)","green":"rgb(75, 192, 192)","blue":"rgb(54, 162, 235)","purple":"rgb(153, 102, 255)","grey":"rgb(201, 203, 207)"};

    kobbelChart = false;
    sprogChart = false;
    colorChart = false;
    onlineChart = false;
    createdChart = false;

    componentDidMount()
    {
        
        this.makeCharts();
        this.props.api("stats").then(stats => {
            if(stats)
            {
                this.setState({
                    stats: stats.data
                });
            }
        });
        this.props.api("kbHistoryStatsHourly").then(statsHourly => {
            
            this.props.api("kbHistoryStats").then(stats => {
                if(stats)
                {
                    this.setState({
                        kbHistoryHourly: statsHourly.data,
                        kbHistory: stats.data,
                    });
                    requestAnimationFrame(() => this.makeKbChart());
                }
            });
        });
    }
    componentDidUpdate()
    {
        this.makeCharts();

    }

    makeCharts()
    {
        this.makeOnlineChart();
        // this.makeKobbelChart();
        this.makeCreatedChart();
        // this.makeSprogChart();
        this.makeColorChart();
    }

    setSort(key)
    {
        console.log("Set sort", key);
        this.setState({
            sortBy: key,
            type: this.state.sortBy === key ? (this.state.type === "ASC" ? "DESC" : "ASC") : "ASC",
        });
    }

    makeKbChart()
    {
        if(this.sprogChart)
        {
            this.sprogChart.destroy();
        }

        // const counted = this.props.users.map(d => ({
        //     ...d,
        //     created: Number(d.created),
        // }))
        // .sort((a, b) => a.created - b.created)
        // .filter((d, i, arr) => arr.findIndex(usr => usr.username === d.username) === i)
        // .map(d => {
        //     let curr = new Date(d.created);
        //     curr.setHours(0,0,0,0);
        //     return {
        //         ...d,
        //         c: curr.getTime()
        //     }
        // }).reduce((counter, curr) => {
        //     if(!counter[curr.c])
        //     {
        //         counter[curr.c] = {
        //             day: curr.c,
        //             count: 0
        //         };
        //     }

        //     counter[curr.c].count++;

        //     return counter;
        // }, {});

        // const goodKeys = Object.keys(counted).filter(d => counted[d].day > 1609887600000);
        const filteredDays = this.state.onlyAfterLarsFilter ? this.state.kbHistoryHourly.filter(d => d.date > 1643619600000) : this.state.kbHistory;
        console.log("KB data", filteredDays);
        const ctx = this.refs.sprogChart.getContext('2d');
        this.sprogChart = new window.Chart(ctx, {
			type: 'line',
			data: {
				labels: filteredDays.map(d => new Date(d.date).toLocaleString()),
				datasets: [{
					label: 'Antal',
					backgroundColor: "rgb(153 80 6 / 50%)",
					borderColor: "#995006",
					data: filteredDays.map(d => d.count),
					fill: true,
				}]
			},
			options: {
                animation: {
                    duration: 0
                },
				responsive: true,
				title: {
					display: true,
					text: 'Kødben'
				},
				tooltips: {
					mode: 'index',
					intersect: false,
				},
				hover: {
					mode: 'nearest',
					intersect: true
                },
                legend: {
                    display: false,
                },
				scales: {
					xAxes: [{
						display: true,
						scaleLabel: {
							display: false,
						}
					}],
					yAxes: [{
						display: true,
						scaleLabel: {
							display: false,
						}
					}]
				}
			}
		});
    }

    makeCreatedChart(){
        // return;
        if(this.createdChart)
        {
            this.createdChart.destroy();
        }

        const counted = this.props.users.map(d => ({
            ...d,
            created: Number(d.created),
        }))
        .sort((a, b) => a.created - b.created)
        .filter((d, i, arr) => arr.findIndex(usr => usr.username === d.username) === i)
        .map(d => {
            let curr = new Date(d.created);
            curr.setHours(0,0,0,0);
            return {
                ...d,
                c: curr.getTime()
            }
        }).reduce((counter, curr) => {
            if(!counter[curr.c])
            {
                counter[curr.c] = {
                    day: curr.c,
                    count: 0
                };
            }

            counter[curr.c].count++;

            return counter;
        }, {});

        const goodKeys = Object.keys(counted).filter(d => counted[d].day > 1609887600000);

        const ctx = this.refs.createdChart.getContext('2d');
        this.createdChart = new window.Chart(ctx, {
			type: 'line',
			data: {
				labels: goodKeys.map(d => new Date(counted[d].day).toLocaleDateString()),
				datasets: [{
					label: 'Antal',
					backgroundColor: "rgb(173 39 39 / 25%)",
					borderColor: "#ad2727",
					data: goodKeys.map(d => counted[d].count),
					fill: true,
				}]
			},
			options: {
                animation: {
                    duration: 0
                },
				responsive: true,
				title: {
					display: true,
					text: 'Nye brugere pr. dag'
				},
				tooltips: {
					mode: 'index',
					intersect: false,
				},
				hover: {
					mode: 'nearest',
					intersect: true
                },
                legend: {
                    display: false,
                },
				scales: {
					xAxes: [{
						display: true,
						scaleLabel: {
							display: false,
						}
					}],
					yAxes: [{
						display: true,
						scaleLabel: {
							display: false,
						}
					}]
				}
			}
		});

       
        // const kobbelCount = this.props.users.reduce((count, curr) => {
        //     count[curr.kobbel]++;
        //     return count;
        // }, kobbelNames.map(d => 0));
        // console.log("Kobbel chart", kobbelCount);
        // const ctx = this.refs.kobbelChart.getContext('2d');
        // this.createdChart = new window.Chart(ctx, {
		// 	type: 'line',
		// 	data: {
		// 		labels: history.map(d => new Date(Number(d.unix)).toLocaleString()),
		// 		datasets: [{
		// 			label: 'KB',
		// 			backgroundColor: "#f30707",
		// 			borderColor: "#ad2727",
		// 			data: history.map(d => d.amount),
		// 			fill: false,
		// 		}]
		// 	},
		// 	options: {
		// 		responsive: true,
		// 		title: {
		// 			display: true,
		// 			text: 'Kobbel fordeling'
		// 		},
		// 	}
		// });
    }

    getDays(from, to)
    {
        let ret = [];
        const day = 1000 * 60 * 60 * 24;
        const diff = Math.ceil((to - from) / day);
        let tempDate = new Date();
        tempDate.setHours(0,0,0,0);
        for(let i = diff; i >= 0; i--)
        {
            const temp = {
                from: tempDate.getTime(),
                to: 0,
            };
            tempDate.setHours(23,59,59,999);
            temp.to = tempDate.getTime();

            tempDate = new Date(temp.from - day);
            ret.push(temp);
        }
        return ret.reverse();
    }

    makeOnlineChart(){
        if(this.onlineChart)
        {
            this.onlineChart.destroy();
        }
        let data = (this.state.stats ? this.state.stats.online : []).map(d => ({...d, unix: Number(d.unix)}));//.filter(d => 1628533329684 <= d.unix);
        if(1)
        {
            const tempDate = new Date("03/17/2022 00:00");
            console.log("Hmm", data);
            data = data.filter(d => d.unix > tempDate.getTime());
        }
        
        const ctx = this.refs.kobbelChart.getContext('2d');
        const labels = data.length > 0 ? this.getDays(Number(data[0].unix), Number(data[data.length - 1].unix)) : [];
        this.onlineChart = new window.Chart(ctx, {
			type: 'line',
			data: {
				labels: labels.map(d => new Date(Number(d.from)).toLocaleDateString()),
				datasets: [{
					label: 'Max',
					backgroundColor: "rgb(73 251 15 / 25%)",
					borderColor: "#49fb0f",
					data: labels.map(d => {
                        const found = data.filter(dat => dat.unix >= d.from && dat.unix <= d.to).sort((a, b) => b.amount - a.amount);
                        return found.length > 0 ? found[0].amount : 0;
                    }),
					fill: true,
				},{
					label: 'Avg.',
					backgroundColor: "rgb(20 123 226 / 50%)",
					borderColor: "#147be2",
					data: labels.map(d => {
                        const found = data.filter(dat => dat.unix >= d.from && dat.unix <= d.to).sort((a, b) => b.amount - a.amount);
                        return found.length > 0 ? Math.floor((found.reduce((count, curr) => count + curr.amount, 0) / found.length)) : 0;
                    }),
					fill: true,
				},{
					label: 'Min',
					backgroundColor: "rgb(251 14 14 / 75%)",
					borderColor: "#fb0f0f",
					data: labels.map(d => {
                        const found = data.filter(dat => dat.unix >= d.from && dat.unix <= d.to).sort((a, b) => a.amount - b.amount);
                        return found.length > 0 ? found[0].amount : 0;
                    }),
					fill: true,
				},]
			},
			options: {
                animation: {
                    duration: 0
                },
				responsive: true,
				title: {
					display: true,
					text: 'Online brugere'
				},
				tooltips: {
					mode: 'index',
					intersect: false,
				},
				hover: {
					mode: 'nearest',
					intersect: true
                },
                legend: {
                    display: false,
                },
				scales: {
					xAxes: [{
						display: true,
						scaleLabel: {
							display: false,
                        },
                        ticks: {
                            callback: value => value.split(", ")[0],
                        }
					}],
					yAxes: [{
						display: true,
						scaleLabel: {
							display: false,
						}
					}]
				}
			}
		});
    }

    makeKobbelChart(){
        if(this.kobbelChart)
        {
            this.kobbelChart.destroy();
        }
        const kobbelCount = this.props.users.reduce((count, curr) => {
            count[curr.kobbel]++;
            return count;
        }, kobbelNames.map(d => 0));

        const ctx = this.refs.kobbelChart.getContext('2d');
        this.kobbelChart = new window.Chart(ctx, {
			type: 'line',
			data: {
				datasets: [{
					data: kobbelCount,
					backgroundColor: [
						this.colors.red,
						this.colors.orange,
						this.colors.yellow,
						this.colors.green,
						this.colors.blue,
					],
					label: 'Kobbel'
				}],
				labels: kobbelNames
			},
			options: {
				responsive: true,
                legend: {
                    display: false,
                },
				title: {
					display: true,
					text: 'Kobbel fordeling'
				},
			}
		});
    }

    makeColorChart(){
        if(this.colorChart)
        {
            this.colorChart.destroy();
        }
        const colorCount = this.props.users.sort((a, b) => {
            const aColor = window.PIXI.utils.hex2rgb(a.color);
            const bColor = window.PIXI.utils.hex2rgb(b.color);

            const aLuma = 0.3 * (aColor[0] * 255) + 0.59 * (aColor[1] * 255) + 0.11 * (aColor[2] * 255);
            const bLuma = 0.3 * (bColor[0] * 255) + 0.59 * (bColor[1] * 255) + 0.11 * (bColor[2] * 255);
            //a.color - b.color
            return aLuma - bLuma;
        }).reduce((count, curr) => {
            if(!count[curr.color])
            {
                count[curr.color] = 0;
            }
            count[curr.color]++;
            return count;
        }, {});
        console.log("Color chart", colorCount);
        // return;
        const ctx = this.refs.colorChart.getContext('2d');

        this.colorChart = window.Chart.Scatter(ctx, {
            data: {
                datasets: Object.keys(colorCount).map((key, x) => {
                    const color = window.PIXI.utils.hex2rgb(key);
                    return {
                        label: key,
                        borderColor: `rgba(${color[0] * 215}, ${color[1] * 215}, ${color[2] * 215}, 1)`,
                        backgroundColor: `rgba(${color[0] * 255}, ${color[1] * 255}, ${color[2] * 255}, 1)`,
                        data: [{
                            x,
                            y: colorCount[key]
                        }]
                    };
                }),
            },
            options: {
                title: {
                    display: true,
                    text: 'Farve fordeling'
                },
                legend: {
                    display: false,
                },
            }
        });
        // this.colorChart = new window.Chart(ctx, {
		// 	type: 'pie',
		// 	data: {
		// 		datasets: [{
		// 			data: Object.values(colorCount),
		// 			backgroundColor: Object.keys(colorCount).map(d => {
        //                 const color = window.PIXI.utils.hex2rgb(d);
        //                 console.log(`rgb(${color[0]}, ${color[1]}, ${color[2]})`);
        //                 return `rgba(${color[0] * 255}, ${color[1] * 255}, ${color[2] * 255}, 1)`;
        //             }),//"#" + d.toString(16)),
		// 			label: 'Kobbel'
		// 		}],
		// 		labels: Object.keys(colorCount)
		// 	},
		// 	options: {
		// 		responsive: true,
		// 		title: {
		// 			display: true,
		// 			text: 'Kobbel fordeling'
        //         },
                
		// 	}
		// });
    }

    makeSprogChart(){
        if(this.sprogChart)
        {
            this.sprogChart.destroy();
        }
        const sprogCount = this.props.users.reduce((count, curr) => {
            if(!count[curr.lang])
            {
                count[curr.lang] = 0;
            }
            count[curr.lang]++;
            return count;
        }, {});

        console.log("Sprog chart", sprogCount);
        const ctx = this.refs.sprogChart.getContext('2d');
        this.sprogChart = new window.Chart(ctx, {
			type: 'pie',
			data: {
				datasets: [{
					data: Object.values(sprogCount),
					backgroundColor: [
						this.colors.red,
						this.colors.orange,
						this.colors.yellow,
						this.colors.green,
						this.colors.blue,
					],
					label: 'Sprog'
				}],
				labels: Object.keys(sprogCount),
            },
           
			options: {
                responsive: true,
				title: {
					display: true,
					text: 'Sprog fordeling'
                },
                 
			}
		});
    }
    
    render(){
        const stats = this.state.stats;
        // console.log("Stats", stats, this.props);
        const almostDead = this.props.users.filter(usr => usr.lifepoints >= 0 && usr.lifepoints < 25).sort((a, b) => a.lifepoints - b.lifepoints);//.filter((_, i) => i < 300);
        const dead = this.props.users.filter(usr => usr.lifepoints <= 0);
        // const notSignedIn = this.props.users.sort((a, b) => a.lastLogin - b.lastLogin).filter(d => (Date.now() - d.lastLogin) >= (1000 * 60 * 60 * 24 * 7)).filter((_, i) => i < 25);
        console.log("Not signed", this.props);
        return (<div className="info">
            <h1>Stats</h1>
            <div className="kpis">
                <div className="kpi">
                    <div className="val">
                        {
                            formatNumber(this.props.users.filter(user => user.admin === 0).reduce((num, user) => num + Number(user.kb), 0))
                        }
                    </div>
                    kb i alt
                </div>
                <div className="kpi">
                    <div className="val">
                        {
                            formatNumber(stats.dayDigsSum || 0)
                        }
                    </div>
                    kb gravet i dag
                </div>
                <div className="kpi">
                    <div className="val">
                        {
                            formatNumber(stats.weekDigsSum || 0)
                        }
                    </div>
                    kb gravet denne uge
                </div>
                <div className="kpi">
                    <div className="val">
                        {
                            formatNumber(stats.hourDigsCount || 0)
                        }
                    </div>
                    har gravet i denne time
                </div>
                <div className="kpi">
                    <div className="val">
                        {
                            formatNumber(stats.dayDigsLength || 0)
                        }
                    </div>
                    har gravet i dag
                </div>
                <div className="kpi">
                    <div className="val">
                        {
                            formatNumber(stats.usersDay || 0)
                        }
                    </div>
                    nye i dag
                </div>
                <div className="kpi">
                    <div className="val">
                        {
                            formatNumber(stats.usersWeek || 0)
                        }
                    </div>
                    nye denne uge
                </div>
                <div className="kpi">
                    <div className="val">
                        {
                            formatNumber(stats.loginDay || 0)
                        }
                    </div>
                    logget ind  i dag
                </div>
                <div className="kpi">
                    <div className="val">
                        {
                            formatNumber((dead.length / this.props.users.length) * 100)
                        }%
                    </div>
                    er død
                </div>
            </div>

            <div className="charts">
                <div className="chart-half">
                    <canvas ref="createdChart" style={{width: "100%"}}></canvas>
                </div>
                <div className="chart-half">
                    <canvas ref="kobbelChart" style={{width: "100%"}}></canvas>
                </div>
            </div>
            <div className="charts">
                <div className="chart-half">
                    <button onClick={() => {
                        this.setState({onlyAfterLarsFilter: !this.state.onlyAfterLarsFilter});
                        requestAnimationFrame(() => this.makeKbChart());
                    }}>Toggle pre lars</button>
                    <canvas ref="sprogChart" style={{width: "100%"}}></canvas>
                </div>
                <div className="chart-half">
                    <canvas ref="colorChart" style={{width: "100%"}}></canvas>
                </div>
                {/* <div className="chart">
                    <canvas ref="kobbelChart" style={{width: "100%"}}></canvas>
                </div> */}
            </div>

            <div className="charts">
                <div className="chart">
                    <h3>Gravest den sidste time</h3>
                    <table>
                        <tr>
                            <td>
                                #
                            </td>
                            <td>
                                Hundenavn
                            </td>
                            <td>
                                Brugernavn
                            </td>
                            <td>
                                KB
                            </td>
                        </tr>
                        {
                            (stats.hourDigs || []).filter(usr => usr.diff > 0).map(usr => {
                                // const target = this.props.users.find(u => u.id === usr.userID);
                                // console.log("Target", target, usr);
                                return (<tr id={usr.id} style={{cursor: "pointer"}} onClick={() => this.props.history.push("/user/" + usr.userID)}>
                                    <td>
                                        {usr.userID}
                                    </td>
                                    <td>
                                        {usr.name}
                                    </td>
                                    <td>
                                        {usr.username}
                                    </td>
                                    <td className="text-success">
                                        +{formatNumber(usr.diff)}kb
                                    </td>
                                </tr>);
                            })
                        }
                    </table>
                </div>
                <div className="chart">
                    <h3>Gravest mest i dag</h3>
                    <table>
                    <tr>
                            <td>
                                #
                            </td>
                            <td>
                                Hundenavn
                            </td>
                            <td>
                                Brugernavn
                            </td>
                            <td>
                                KB
                            </td>
                        </tr>
                        {
                            (stats.dayDigs || []).filter(usr => usr.diff > 0).map((usr) => {
                                // const target = this.props.users.find(u => u.id === usr.userID);
                                return (<tr id={usr.id} style={{cursor: "pointer"}} onClick={() => this.props.history.push("/user/" + usr.userID)}>
                                    <td>
                                        {usr.userID}
                                    </td>
                                    <td>
                                        {usr.name}
                                    </td>
                                    <td>
                                        {usr.username}
                                    </td>
                                    <td className="text-success">
                                        +{formatNumber(usr.diff)}kb
                                    </td>
                                </tr>);
                            })
                        }
                    </table>
                </div>
                <div className="chart" style={{maxHeight: "716px", overflowY: "auto"}}>
                    <h3>Tæt på at dø</h3>
                    <table>
                        <tr>
                            <td>
                                #
                            </td>
                            <td>
                                Hundenavn
                            </td>
                            <td>
                                Lifepoints
                            </td>
                            <td>
                                KB
                            </td>
                        </tr>
                        {
                            almostDead.map(usr => <tr key={usr.id} style={{cursor: "pointer"}} onClick={() => this.props.history.push("/user/" + usr.id)}>
                                <td>
                                    {usr.id}
                                </td>
                                <td>
                                    {usr.name}
                                </td>
                                <td>
                                    {usr.lifepoints}
                                </td>
                                <td>
                                    {usr.kb}
                                </td>
                            </tr>)
                        }
                    </table>
                </div>
            </div>
        </div>);
    }
}