import React from "react";
import "./animator.css";

import JSZip from "jszip";


const bounceOut = function (x) {
	const n1 = 7.5625;
	const d1 = 2.75;

	if (x < 1 / d1) {
		return n1 * x * x;
	} else if (x < 2 / d1) {
		return n1 * (x -= 1.5 / d1) * x + 0.75;
	} else if (x < 2.5 / d1) {
		return n1 * (x -= 2.25 / d1) * x + 0.9375;
	} else {
		return n1 * (x -= 2.625 / d1) * x + 0.984375;
	}
};
const pow = Math.pow;
const sqrt = Math.sqrt;
const sin = Math.sin;
const cos = Math.cos;
const PI = Math.PI;
const c1 = 1.70158;
const c2 = c1 * 1.525;
const c3 = c1 + 1;
const c4 = (2 * PI) / 3;
const c5 = (2 * PI) / 4.5;

export default class hatAnimator extends React.Component {
  ctx;
  canvas;
  hat = new Image();

  state = {
    canvasWidth: 300,
    canvasHeight: 300,

    animate: false,
    animateTime: 1500,
    animateDistanceY: 10,
    animateEasing: "easeOutCubic",
    frames: 10,

    glow: false,
    glowStrength: 10,
    glowColor: "#e8e216",
  };
  exporting = false;

  componentDidMount()
  {
    // this.hat.src = "pote.png";
    // this.hat.onload = () => this.autoSize();
    setInterval(() => this.updateImage(), 1000 / 60);
  }

  easing = {
    //   easeOutCubic: x => 1 - Math.pow(1 - x, 3),
    //   easeOutSine: x => Math.sin((x * Math.PI) / 2),
    //   easeOutCirc: x => Math.sqrt(1 - Math.pow(x - 1, 2)),
    //   linear: x => x,

      linear: (x) => x,
    easeInQuad: function (x) {
        return x * x;
    },
    easeOutQuad: function (x) {
        return 1 - (1 - x) * (1 - x);
    },
    easeInOutQuad: function (x) {
        return x < 0.5 ? 2 * x * x : 1 - Math.pow(-2 * x + 2, 2) / 2;
    },
    easeInCubic: function (x) {
        return x * x * x;
    },
    easeOutCubic: function (x) {
        return 1 - Math.pow(1 - x, 3);
    },
    easeInOutCubic: function (x) {
        return x < 0.5 ? 4 * x * x * x : 1 - Math.pow(-2 * x + 2, 3) / 2;
    },
    easeInQuart: function (x) {
        return x * x * x * x;
    },
    easeOutQuart: function (x) {
        return 1 - Math.pow(1 - x, 4);
    },
    easeInOutQuart: function (x) {
        return x < 0.5 ? 8 * x * x * x * x : 1 - Math.pow(-2 * x + 2, 4) / 2;
    },
    easeInQuint: function (x) {
        return x * x * x * x * x;
    },
    easeOutQuint: function (x) {
        return 1 - Math.pow(1 - x, 5);
    },
    easeInOutQuint: function (x) {
        return x < 0.5 ? 16 * x * x * x * x * x : 1 - Math.pow(-2 * x + 2, 5) / 2;
    },
    easeInSine: function (x) {
        return 1 - Math.cos((x * Math.PI) / 2);
    },
    easeOutSine: function (x) {
        return Math.sin((x * Math.PI) / 2);
    },
    easeInOutSine: function (x) {
        return -(Math.cos(Math.PI * x) - 1) / 2;
    },
    easeInExpo: function (x) {
        return x === 0 ? 0 : Math.pow(2, 10 * x - 10);
    },
    easeOutExpo: function (x) {
        return x === 1 ? 1 : 1 - Math.pow(2, -10 * x);
    },
    easeInOutExpo: function (x) {
        return x === 0
            ? 0
            : x === 1
            ? 1
            : x < 0.5
            ? Math.pow(2, 20 * x - 10) / 2
            : (2 - Math.pow(2, -20 * x + 10)) / 2;
    },
    easeInCirc: function (x) {
        return 1 - Math.sqrt(1 - Math.pow(x, 2));
    },
    easeOutCirc: function (x) {
        return Math.sqrt(1 - Math.pow(x - 1, 2));
    },
    easeInOutCirc: function (x) {
        return x < 0.5
            ? (1 - Math.sqrt(1 - Math.pow(2 * x, 2))) / 2
            : (Math.sqrt(1 - Math.pow(-2 * x + 2, 2)) + 1) / 2;
    },
    easeInBack: function (x) {
        return c3 * x * x * x - c1 * x * x;
    },
    easeOutBack: function (x) {
        return 1 + c3 * Math.pow(x - 1, 3) + c1 * Math.pow(x - 1, 2);
    },
    easeInOutBack: function (x) {
        return x < 0.5
            ? (Math.pow(2 * x, 2) * ((c2 + 1) * 2 * x - c2)) / 2
            : (Math.pow(2 * x - 2, 2) * ((c2 + 1) * (x * 2 - 2) + c2) + 2) / 2;
    },
    easeInElastic: function (x) {
        return x === 0
            ? 0
            : x === 1
            ? 1
            : -Math.pow(2, 10 * x - 10) * Math.sin((x * 10 - 10.75) * c4);
    },
    easeOutElastic: function (x) {
        return x === 0
            ? 0
            : x === 1
            ? 1
            : Math.pow(2, -10 * x) * Math.sin((x * 10 - 0.75) * c4) + 1;
    },
    easeInOutElastic: function (x) {
        return x === 0
            ? 0
            : x === 1
            ? 1
            : x < 0.5
            ? -(Math.pow(2, 20 * x - 10) * Math.sin((20 * x - 11.125) * c5)) / 2
            : (Math.pow(2, -20 * x + 10) * Math.sin((20 * x - 11.125) * c5)) / 2 + 1;
    },
    easeInBounce: function (x) {
        return 1 - bounceOut(1 - x);
    },
    easeOutBounce: bounceOut,
    easeInOutBounce: function (x) {
        return x < 0.5
            ? (1 - bounceOut(1 - 2 * x)) / 2
            : (1 + bounceOut(2 * x - 1)) / 2;
    },
  };

  
  animateDir = 0;
  // animI = 0;
  animateMiddle = false;
  animStart = Date.now();
  updateImage()
  {
    if(this.exporting)
    {
      return;
    }
    const tempProgress = Math.min((Date.now() - this.animStart) / this.state.animateTime, 1);
    const progress = Math.floor(tempProgress * this.state.frames) / this.state.frames;
    this.drawFrame(progress);
  }

  drawFrame(progress)
  {
    let x = (this.state.canvasWidth / 2) - (this.hat.width / 2);
    let y = (this.state.canvasHeight) - (this.hat.height);
    this.canvas.width = this.canvas.width;
    //Apply glow
    if(this.state.glow)
    {
      this.ctx.shadowColor = this.state.glowColor;
      this.ctx.shadowBlur = this.state.glowStrength;
      y -= this.state.glowStrength / 2;
    }

    //Apply animation
    if(this.state.animate)
    {
      // y -= this.animI;
      // this.animI++;
      
      if(this.animateDir === 1) // Up
      {
        y -= this.easing[this.state.animateEasing](progress) * this.state.animateDistanceY;
      }else{ // Down
        y -= this.state.animateDistanceY - (this.easing[this.state.animateEasing](progress) * this.state.animateDistanceY);
      }


      if(progress === 1)
      {
        this.animStart = Date.now();
        this.animateDir = this.animateDir === 1 ? 0 : 1;
      }
      // console.log(progress);
    }
    
    if(this.ctx)
    {
        this.ctx.imageSmoothingEnabled = false;
        this.ctx.drawImage(this.hat, x, y, this.hat.width, this.hat.height);
    }
  }

  autoSize()
  {
    this.canvas.width = this.hat.width;
    this.canvas.height = this.hat.height;
    this.setState({
      canvasWidth: this.hat.width,
      canvasHeight: this.hat.height,
    });
  }

  exportImages()
  {
    this.animateDir = 1;
    this.exporting = true;
    requestAnimationFrame(() => {
      
      var zip = new JSZip();
      if(this.state.animate)
      {
        for(let j = 0; j < 2; j++)
        {
          for(let i = 0; i <= this.state.frames; i++)
          {
            
            this.drawFrame(i / this.state.frames);
            // console.log(i / this.state.frames, `${i + (this.state.frames* j)}.png`);
            // let link = document.createElement('a');
            // link.download = `${i + (this.state.frames * j)}.png`;
            // link.href = this.canvas.toDataURL();
            // link.click();
            zip.file(`${i + (this.state.frames * j)}.png`, this.canvas.toDataURL().split('base64,')[1], {base64: true});//, {base64: true});


          }
        }
      }else{
        this.drawFrame(1);
        zip.file(`0.png`, this.canvas.toDataURL().split('base64,')[1], {base64: true});
      }

      zip.generateAsync({type:"blob"})
      .then(function(content) {
          // see FileSaver.js
          // saveAs(content, "example.zip");
          let link = document.createElement('a');
          link.download = `frames.zip`;
          link.href = URL.createObjectURL(content);
          link.click();
          console.log("Content", content);
      });

      this.exporting = false;
    });
  }

  render(){
    return (
      <div className="animator">
          <canvas ref={d => {
            if(d)
            {
              this.canvas = d;
              this.ctx = d.getContext('2d');
            }
          }} width={this.state.canvasWidth} height={this.state.canvasHeight}/>
            <div className="baseSettings">
              <input type="file" onChange={e => {
                this.hat.src = URL.createObjectURL(e.target.files[0]);
                this.hat.onload = () => this.autoSize();

              }}/>
              <button onClick={() => this.exportImages()}>Eksporter billeder</button>
            </div>
          <div className="settings">
            <div className="setting">
              <h2>Glow</h2>
              <label for="glow">Enable</label>
              <input type="checkbox" id="glow" defaultChecked={this.state.glow} onChange={e => this.setState({glow: e.target.checked})}/>
              <label for="glowColor">Color</label>
              <input type="color" id="glowColor" defaultValue={this.state.glowColor} onChange={e => this.setState({glowColor: e.target.value})}/>
              <label for="glowStrength">Strength</label>
              <input type="range" min="0.1" max="50" id="glowStrength" defaultValue={this.state.glowStrength} onChange={e => this.setState({glowStrength: e.target.value})}/>
            </div>
            <div className="setting">
              <h2>Animate</h2>
              <label for="animate">Enable</label>
              <input type="checkbox" id="animate" defaultChecked={this.state.animate} onChange={e => this.setState({animate: e.target.checked})}/>
              
              <label for="animateTime">Time [ms]</label>
              <input type="number" id="animateTime" defaultValue={this.state.animateTime} onChange={e => this.setState({animateTime: e.target.value})}/>
              <label for="frames">Frames</label>
              <input type="number" id="frames" defaultValue={this.state.frames} onChange={e => this.setState({frames: e.target.value})}/>

              
              <label for="animateDistanceY">Y distance</label>
              <input type="number" id="animateDistanceY" defaultValue={this.state.animateDistanceY} onChange={e => this.setState({animateDistanceY: e.target.value})}/>
              <label for="animateEasing">Easing</label>
              <select onChange={(e) => this.setState({animateEasing: e.target.value})}>
                {
                  Object.keys(this.easing).map((d, i) => <option value={d} key={i}>{d}</option>)
                }
              </select>

            </div>
            <div className="setting">
              <h2>Canvas</h2>
              
              <label for="canvasHeight">Height [px]</label>
              <input type="number" id="canvasHeight" value={this.state.canvasHeight} onChange={e => this.setState({canvasHeight: e.target.value})}/>
              
              <label for="canvasWidth">Width [px]</label>
              <input type="number" id="canvasWidth" value={this.state.canvasWidth} onChange={e => this.setState({canvasWidth: e.target.value})}/>

            </div>
          </div>
      </div>
    );
  }
};