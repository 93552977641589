import React from "react";
import "./HatList.css";
import {dataSort, getGfx, getType, api} from "../helpers";
import Cron from 'react-cron-generator';
import CronBuilder from  'react-cron-builder';
import 'react-cron-builder/dist/bundle.css'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";

const types = ["AutoSniffer", "Auto-dropper", ""]

export default class Bots extends React.Component {
    state = {
        count: {},
        items: [],

        sortBy: "id",
        type: "DESC",
        search: "",

        filterType: "all",
        editing: false,
        accounts: [],
        types: [],
        data: [],

        inputs: {},
    };

    componentDidMount()
    {
        this.getData();
    }

    async getData()
    {
        const [data, accounts, types] = await Promise.all([
            this.props.api("bots"),
            this.props.api("bots/accounts"),
            this.props.api("bots/types"),
        ]);

        console.log("hehe", types);
        this.setState({
            types: types?.scripts ?? types,
            accounts: accounts.data,
            data: data.data,
        })
    }

    async doSave(e)
    {
        e.preventDefault();
        const old = this.state.editing !== false ? this.state.data[this.state.editing] : {};
        const data = {
            ...old,
            ...this.state.inputs
        };
        this.setState({editing: false});
        console.log("Submit", data);
        await this.props.api("bots", "POST", data);
        // this.getData();
        
        setTimeout(() => this.getData(), 500);
    }

    setValue(key, value)
    {
        const inputs = {
            ...this.state.inputs
        };

        inputs[key] = value;
        this.setState({
            inputs
        });
    }

    render(){
        const data = this.state.editing !== false ? this.state.data[this.state.editing] : {};
        console.log("", this.state, data)
        return (<div className="items">
            {
                this.state.editing !== false && <form onSubmit={(e) => this.doSave(e)}>
                    Type:<br/>
                    <select name="type" defaultValue={data?.type} onChange={e => this.setValue("type", e.target.value)}>
                    <option/>
                        {this.state.types.map(type => <option value={type} key={type}>{type}</option>)}
                    </select><br/>

                    Account:<br/>   
                    <select name="account" defaultValue={data?.account} onChange={e => this.setValue("account", e.target.value)}>
                        <option/>
                        {this.state.accounts.map(acc => <option value={acc.dogid} key={acc.dogid}>{acc.dogname}</option>)}
                    </select><br/>

                    Aktiv:<br/>
                    <input type="radio" name="active" value="0" onChange={() => this.setValue("active", 0)} defaultChecked={data?.active === 0}/> 0
                    <input type="radio" name="active" value="1" onChange={() => this.setValue("active", 1)} defaultChecked={data?.active === 1}/> 1<br/>

                    {/* Values:<br/>
                    <select name="account" defaultValue={data?.account} onChange={e => this.setValue("account", e.target.value)}>
                        <option/>
                        {this.state.accounts.map(acc => <option value={acc.dogid} key={acc.dogid}>{acc.dogname}</option>)}
                    </select><br/> */}

                    Tidsinterval:<br/>
                    <button onClick={(e) => {
                        e.preventDefault();
                        this.setValue("interval", "instant");
                    }}>instant</button><br/>
                    {/* <Cron
                    onChange={(e)=> this.setValue("interval", e)}
                    value={data?.interval}
                    showResultText={true}
                    showResultCron={true}
                    
                    /> */}
                    <CronBuilder 
                        value={data?.interval}
                        onChange={(e)=> this.setValue("interval", e)}
                        showResult={false}
                    />

                    <button>Gem</button>
                </form>
            }
            <h2>Bots</h2> <button onClick={() => this.setState({editing: -1})}>+</button>
            <table style={{width: "100%"}}>
                <tr style={{fontWeight: "bolder"}}>
                    <td>Type</td>
                    <td>Tidsinterval</td>
                    <td>Hund</td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                {
                    (this.state.data||[]).map((bot, i) => {
                        const dog = this.state.accounts.find(dog => dog.dogid === bot.account);
                        return (<tr>
                            <td>{bot.type}</td>
                            <td>{bot.interval}</td>
                            <td>{dog?.dogname}</td>
                            <td>{bot.active ? "Aktiv" : "Inaktiv"}</td>
                            <td>
                                <button onClick={() => this.setState({editing: i})}>Edit</button>
                            </td>
                            <td>
                                <button onClick={async () => {
                                    const check = window.confirm("Sikker på du vil slette?");
                                    if(check)
                                    {
                                        await this.props.api("bots/delete", "POST", {
                                            id: bot.id,
                                        });
                                        setTimeout(() => this.getData(), 500);
                                    }
                                }}>Remove</button>
                            </td>
                        </tr>);
                    })
                }
            </table>
        </div>);
    }
}