import React from "react";
import "./HatList.css";
import {dataSort} from "../helpers";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";

export default class HatList extends React.Component {
    state = {
        count: {},
        items: [],

        sortBy: "amount",
        type: "ASC",
        search: "",

        sentimental: false,
        unused: true,
    };

    async save()
    {
        const id = Number(this.props.match.params.id);

        await this.props.api("saveHat", "POST", {
            id,
            sentimental: this.state.sentimental,
            rarity: this.state.unused ? -1 : this.refs.rarity.value,
        });
        const next = await this.props.nextItem();
        console.log("Next", next);
        window.location = "/edit/" + next.id;
    }
    //26
    render(){
        const id = Number(this.props.match.params.id);
        const {items} = this.props;

        const target = items[Number(id)];
        console.log(this.props, items);


        const raryties = ["common", "uncommon", "rare", "super rare"];
        const IDs = target.g.split(",");
        return (<div className="items">
            <img src={`https://hundeparken.net/h5/gfx/item/${IDs[0]}.png`} style={{width: "20px"}}/> 
            <h2>{target.n}</h2>
            
            
            <div>
                <input id="senti" type="checkbox" value="1" onChange={() => this.setState({sentimental: !this.state.sentimental})} />
                <label htmlFor="senti">Has sentimental value?</label>
            </div>

            <div>
                <input id="unused" type="checkbox" value="1" ref="unused" defaultChecked={this.state.unused} onChange={(e) => this.setState({unused: e.target.checked})} />
                <label htmlFor="unused">Not used?</label>
            </div>

                <div style={{width: "500px", marginTop: "20px", display: this.state.sentimental ? "none" : "initial"}}>
                    <input type="range" min={0} max={6} step={1} style={{width: "100%"}} ref="rarity" onChange={() => {
                        this.refs.unused.checked = false;
                        this.setState({unused: false});
                    }}/>
                    <div style={{display: "flex", justifyContent: "space-between"}}>
                        {raryties.map((d, i) => <div onClick={() => {
                            this.refs.rarity.value = (i) * 2;
                            this.refs.unused.checked = false;
                            this.setState({unused: false});
                        }}>{d}</div>)}
                    </div>
                </div>

            <button style={{width: "100%", padding: "10px", margin: "60px 0px"}} onClick={() => this.save()}>
                Save and goto next
            </button>
        </div>);
    }
}