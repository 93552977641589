import React from "react";
import "./HatList.css";


export default class AnalyzeDig extends React.Component {
    state = {
        data: false,
        patterns: [],
        highlight: [],
        maxDiff: 100,
    };
    CHEST_TIME = 5000;
    digChart = false;

    componentDidMount()
    {
        if(this.state.data)
        {
            this.findPatters();
            this.createGraph();
        }
    }


    parseData(json)
    {
        try {
            const data = JSON.parse(json);
            this.setState({
                data
            });
            requestAnimationFrame(() => this.componentDidMount());
        } catch (error) {
            
        }
    }

    getRandomColor() {
        let letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
          color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
      }

    createGraph()
    {
        if(this.digChart)
        {
            this.digChart.clear();
            this.digChart.destroy();
        }
        const ctx = this.refs.digGraph.getContext('2d');
        const data = this.state.data.digsDelays.filter(d => d < this.CHEST_TIME);

        const highlights = this.state.highlight.map(i => this.state.patterns[i].patterns.map(d => d.arr)).flat();
        console.log("Hihghligts", highlights);
		this.digChart = new window.Chart(ctx, {
			type: 'line',
			data: {
				labels: data.map((_, i) => i),
				datasets: [
                    
                ...highlights.map((arr, i) => {
                    const COLOR = this.getRandomColor();
                    return {
                        label: `Pattern #${i}`,
                        backgroundColor: COLOR,
                        borderColor: COLOR,
                        data: arr,
                        fill: true,
                    };
                }),

                {
					label: 'digsDelays',
					backgroundColor: "#f30707",
					borderColor: "#ad2727",
					data: data.map(d => d),
					fill: true,
                },
            ]
			},
			options: {
                tooltips: {
                    enabled: false
               },
                legend: {
                    display: false,
                },
                animation: {
                    duration: 0
                },
				responsive: true,
				title: {
					display: true,
					text: 'Timestamps'
				},
				tooltips: {
					mode: 'index',
					intersect: false,
				},
				hover: {
					mode: 'nearest',
					intersect: true
				},
				scales: {
					xAxes: [{
						display: true,
						scaleLabel: {
							display: true,
							labelString: 'Tidspunkt'
						}
					}],
					yAxes: [{
						display: true,
						scaleLabel: {
							display: true,
							labelString: 'Value'
                        },
                        ticks: {
                            min: 0,
                            max: this.CHEST_TIME,
                            stepSize: 20
                        }
					}]
				}
			}
		});
    }

    findPatters()
    {
        const data = [];
        const noChestData = this.state.data.digsDelays.filter(d => d < this.CHEST_TIME);
        let patternSize = 2;
        let patterns = ["a"];
        while(patterns.length > 0)
        {
            patterns = [];
            for(let i = 0; i < noChestData.length - (patternSize - 1); i++)
            {
                const [matches, pattern, newDataArr] = this.checkPattern(i, patternSize, noChestData);
                if(matches > 1)
                {
                    // console.log(i, "=", matches);
                    patterns.push({
                        i,
                        matches,
                        pattern,
                        arr: newDataArr
                    });
                }
            }
            data.push({
                size: patternSize,
                patterns,
            });
            patternSize++;
        }
        console.log("Found patterns", data);
        this.setState({
            patterns: data.reverse().filter((_, i) => i > 0),
        });
    }


    checkPattern(start, n, arr, maxDiff = 50)
    {
        let matches = 0;
        const pattern = [...Array(n)].map((_, i) => arr[start + i]);
        let newArr = [];
        for(let i = 0; i < (arr.length - pattern.length); i++)
        {
            for(let m = 0; m < pattern.length; m++)
            {
                const P = pattern[m];
                const T = arr[i + m];

                const diff = Math.abs(P - T);
                if(diff > maxDiff)
                {
                    newArr.push(-1);
                    break;
                }
                if(m === (pattern.length - 1))
                {
                    for(let j = 0; j < pattern.length; j++)
                    {
                        newArr.push(arr[i + j]);
                    }
                    i += pattern.length - 1;
                    matches++;
                }
            }   
        }
        return [
            matches,
            pattern,
            newArr,
        ];
    }

    dumbCheckPattern(start, n, arr)
    {
        const pattern = [...Array(n)].map((_, i) => arr[start + i]);
        
        // console.log(pattern);
        let matches = 0;
        let tempArr = arr.join(",");
        while(tempArr.includes(pattern))
        {
            tempArr = tempArr.replace(pattern, '').replace(",,", ','); 
            // console.log(tempArr);
            matches++;
        }
        // console.log(matches);
        return matches;
    }
    
    render(){
      
        return (<div className="items">
            <h2>Analyzer</h2>
            {
                !this.state.data ? 
                <div>
                    Dig report: <br/>
                    <textarea ref="json"></textarea><br/>
                    <button onClick={() => this.parseData(this.refs.json.value)}>Load</button>
                </div>
                : <div>
                    Sup m8

                    <h2>Dig between</h2>
                    <canvas ref="digGraph" style={{width: "100%"}}></canvas>
                    <h2>Patterns</h2>
                    maxDiff:<br/>
                    <input defaultValue={this.state.maxDiff} onBlur={e => {
                        this.setState({
                            patterns: [],
                            maxDiff: Number(e.target.value),
                            highlight: [],
                        });
                        setTimeout(() => {
                            this.findPatters();
                        }, 500);
                    }} type="number" />
                    <table key={this.state.patterns.length}>
                    <tr>
                            <td>
                                Size
                            </td>
                            <td>
                                Matches
                            </td>
                            <td>
                            </td>
                        </tr>
                        {
                            this.state.patterns.map((pattern, i) => <tr key={i}>
                                <td>
                                    {pattern.size}
                                </td>
                                <td>
                                    {pattern.patterns.reduce((a,b) => a + b.matches, 0)}
                                </td>
                                <td>
                                    <button onClick={() => {
                                        this.setState({
                                            highlight: this.state.highlight.includes(i) ? 
                                                this.state.highlight.filter(d => d !== i)
                                            : [
                                                ...this.state.highlight,
                                                i
                                            ]
                                        });

                                        requestAnimationFrame(() => this.createGraph());
                                    }}>
                                        {this.state.highlight.includes(i) ? "Unhighlight" : "Highlight"}
                                    </button>
                                </td>
                            </tr>)
                        }
                    </table>
                </div>
            }
        </div>);
    }
}