import React from "react";
import "./HatList.css";
import {Link} from "react-router-dom";

import {shops, formatNumber, getGfx} from "../helpers";


export default class ShopViewer extends React.Component {
    state = {
        shopID: 0,
        data: [],

        delete: [],

        shops: {},

        showAll: {},
    };

    componentWillMount()
    {
        // this.setState({
        //     shopID: this.props.match.params.id,
        // })
        // this.loadShop(this.props.match.params.id);
        shops.forEach(async (_, id) => {
            const data = await this.props.api("shopData/" + id);
            if(!data.error)
            {
                const shops = {
                    ...this.state.shops,
                };

                shops[id] = data.data;
                this.setState({
                    shops
                });
            }
        });
    }

    async loadShop(id)
    {
        const data = await this.props.api("shopData/" + id);
        if(!data.error)
        {
            this.setState({
                data: data.data,
                delete: [],
                shopID: this.props.match.params.id,
            });
        }
    }

    toggle(ID)
    {
        const showAll = {...this.state.showAll};
        if(showAll[ID])
        {
            delete showAll[ID];
        }else{
            showAll[ID] = 1;
        }
        

        this.setState({
            showAll
        });
    }


    render(){
        // if(this.props.match.params.id === "0")
        // {
        //     console.log("Hmm", shops);
        //     return (<div>
        //         <h2>Butikker</h2>
        //         {
        //             shops.map((d, i) => <div>
        //                     <Link to={"/shop/" + i} onClick={() => this.loadShop(i)}>{d}</Link>
        //                 </div>)
        //         }
        //     </div>);
        // }
        // console.log("Delete", this.state.delete.join(","));
        console.log("Show all", this.state.showAll);
        return shops.map((shopName, shopI) => {
            if(shopI === 0)
            {
                return null;
            }

            const SHOW_ALL = this.state.showAll[shopI];
            
            return (<div className="items" style={{marginBottom: "25px"}}>
            <h3 key={shopI}>{shopName}</h3>
            <table style={{width: "800px"}}>
            <tr>
                    <td></td>
                    <td>
                        Navn
                    </td>
                    <td>
                        Pris
                    </td>
                    <td>
                        Tidspunkt
                    </td>
                    <td></td>
                </tr>
                {
                    (this.state.shops[shopI] || []).filter(d => SHOW_ALL ? true : d.inStore === 1).map(d => {
                        const item = this.props.items[d.hatID];
                        const IDs = ((item ? item.g : "").split(","));
                        return (<tr onClick={() => this.props.history.push("/view/" + d.hatID)} style={{cursor: "pointer"}}>
                            <td>
                                <img src={getGfx(`item/${IDs[0]}.png`)} loading="lazy" /> 
                            </td>
                            <td>
                                {item && item.n}
                            </td>
                            <td>
                                {formatNumber(d.price)}kb
                            </td>
                            <td>
                                {new Date(Number(d.unix)).toLocaleString()}
                            </td>
                            <td>
                                {d.inStore === 1 && <b>Tilsalg nu</b>}
                            </td>
                            {/* <td>
                                {
                                    !this.state.delete.includes(d.id) && <button onClick={() => this.setState({
                                        delete: [
                                            ...this.state.delete,
                                            d.id
                                        ]
                                    })}>Slet</button>
                                }
                            </td> */}
                        </tr>);
                    })
                }

                    <tr onClick={() => this.toggle(shopI)} style={{cursor: "pointer", fontWeight: "bolder"}}>
                        <td>
                            {SHOW_ALL ? "Skjul historik" : "Se historik"}
                        </td>
                    </tr>
            </table>
        </div>)
        });
    }
}