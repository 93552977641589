import React from "react";
import "./HatList.css";
import {dataSort, getGfx, getType} from "../helpers";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";

export default class Items extends React.Component {
    state = {
        count: {},
        items: [],

        sortBy: "id",
        type: "DESC",
        search: "",

        filterType: "all",
    };


    componentDidMount()
    {
        if(this.refs.search)
        {
            this.refs.search.focus();
        }
    }


    setSort(key)
    {
        this.setState({
            sortBy: key,
            type: this.state.sortBy === key ? (this.state.type === "ASC" ? "DESC" : "ASC") : "ASC",
        });
    }


    // types = ["Hat","Mund","Gøgler","Bombe","Spray","Skub","friendlyexplosive","Ild","Cleanspray","Ryg"];

    

    render(){
        const rawData = dataSort(this.props.items.filter(d => !!d).map((d, i) => ({...d, i})), this.state.sortBy, this.state.type).filter(d => this.state.search.length === 0 || d.n.toLowerCase().includes(this.state.search) || d.d.toLowerCase().includes(this.state.search));
        const data = rawData.filter((d, i) => i < 2500).filter(d => {
            if(this.state.filterType === "all")
            {
                return true;
            }else{
                const check = this.props.itemCount.find(dat => dat.hatID === d.id);
                return this.props.itemCount.length > 0 && (check ? check.amount === 0 : true) && d.u !== 0 && d.u !== 6;
            }
        });
        console.log("Items", data, this.props);
        return (<div className="items">
            <h2>Hatte</h2>
            <div>
                <div>
                    <label htmlFor="all">Alle</label>
                    <input type="radio" id="all" name="type" defaultChecked={true} onClick={() => this.setState({filterType: "all"})}/>
                </div>
                <div>
                    <label htmlFor="unreleased">Hatte som ikke er released</label>
                    <input type="radio" id="unreleased" name="type" onClick={() => this.setState({filterType: "unreleased"})}/>
                </div>
            </div>
            <div className="d-flex justify-content-between align-items-center">
                <div>
                    Viser <b>{Math.min(rawData.length, 1000)}</b> ud af <b>{data.length}</b>.
                </div>
                <input ref="search" className="form-control w-25" placeholder="Søg" onKeyUp={(e) => this.setState({
                    search: e.target.value.toLowerCase(),
                })}/>
            </div>
            <div className="item" style={{fontWeight: "bolder", cursor: "pointer"}}>
                <div>
                   
                </div>
                <div className="name" onClick={() => this.setSort("id")}>
                    ID
                </div>
                <div className="name"  onClick={() => this.setSort("n")} style={{justifyContent: "center"}}>
                    Navn
                </div>
                <div className="name"  onClick={() => this.setSort("d")} style={{justifyContent: "center"}}>
                    Beskrivelse
                </div>
                <div className="center"  onClick={() => this.setSort("u")} style={{justifyContent: "center"}}>
                    Type
                </div>
            </div>
            {
                data.map((item, key) => {
                    const IDs = item.g.split(",");
                    return (<Link to={`/view/${item.id}`} key={item.id}>
                            <div className="item">
                                <div className="img">
                                    {/* <img src={`gfx/item/${IDs[0]}.png`} />  */}
                                    <img src={getGfx(`item/${IDs[0]}.png`)} loading="lazy" style={{maxWidth: "30px", maxHeight: "30px"}} /> 
                                </div>
                                <div className="name">
                                    {item.id}
                                </div>
                                <div className="name">
                                    {item.n}
                                </div>
                                <div className="name">
                                    {item.d}
                                </div>
                                <div className="center">
                                    {getType(item.u)}
                                </div>
                        </div>
                    </Link>);
                })
            }
        </div>);
    }
}