import { ClientJS } from 'clientjs';

// const SERVER_ENDPOINT = true ? "23.88.108.151:555" : "localhost:555";
const SERVER_ENDPOINT = true ? "142.93.174.108:443" : "localhost:555";

export function cryptoRand()
{
    const buffer = new Uint32Array(1);
    (window.crypto || window.msCrypto).getRandomValues(buffer);
    return (buffer[0] / (0xffffffff + 1));
}

export function download(filename, content)
{
    var encodedUri = encodeURI(content);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", filename);
    document.body.appendChild(link); // Required for FF

    link.click();
}

export function getType(type)
{
    switch(type)
    {
        case 1:
            return "Hat";
        case 2:
            return "Mund";
        case 3:
            return "Gøgler";
        case 4:
            return "Bombe";
        case 5:
            return "Cleanspray";
        case 6:
            return "Skub";
        case 7:
            return "Friendlyexplosive";
        case 8:
            return "Ild";
        case 9:
            return "Cleanspray";
        default:
            return "Ryg";
    }
};


export function getGfx(url)
{
    return `http://${SERVER_ENDPOINT}/image/${encodeURIComponent(url)}`;
}


const cjs = new ClientJS();
export function api(endpoint, type = "GET", data = null, apiKey = "no", serverIP = SERVER_ENDPOINT)
{
    const fingerprint = cjs.getFingerprint();
    return fetch(`http://${serverIP}/${endpoint}`, {
        method: type,
        headers: {
            "Content-Type": "application/json",
            "key": apiKey,
            "fp": fingerprint,
        },
        body: data ? JSON.stringify(data) : undefined,
    }).then(d => d.json()).catch(_ => false);
}

export const shops = ["", "Sameshop", "DevShop2", "Igloo shop", "Klanens shop", "Bandens shop", "Sektens shop", "Stammens shop", "Flowerpusher shop", "Selskapets shop", "Losjen shop", "Henke pip shop", "Happy Hippie shop", "Space shop", "Mystery shop", "Magic shop", "Morfshop", "Empty"];

export function formatNumber(val){
    return new Intl.NumberFormat('da-DK', { style: 'currency', currency: 'EUR' }).format(val).split(",")[0];
}

function valToNumber(val)
{
    switch(typeof val)
    {
        case "number":
            return val;
        break;
        case "string":
            return val.charCodeAt(0);
        break;
        default:
            return ;
        break;
    }
}
export function dataSort(data, key, type)
{
    const compare = (a, b) => type === "ASC" ? (a - b) : (b - a);
    return data.sort((a, b) => compare(valToNumber(a[key]), valToNumber(b[key])));
}

export const kobbelNames = ["banden","klanen","losjen","sekten","selskapet","stammen"];


export class Items{
    static itemList = [];
    static itemsTexture = [];

    static processItemList(json)
    {
        json.forEach((item, i)=>
        {
            if(item)
            {
                this.itemList[item.id] = item;
            }
        });
    };
    static getSprite(id)
    {
        var item = Items.itemList[id];
        var itemAnimation = [];
        var itemGfxList = item.g.split(",");
        itemGfxList.forEach((itemGraphics)=>
        {
            itemAnimation.push(this.getItemsTexture(itemGraphics));
        });
        
        var itemSprite = new window.PIXI.AnimatedSprite(itemAnimation);
        itemSprite.animationSpeed = (item.a/10)/(60);
        itemSprite.x = item.x;
        itemSprite.y = item.y;
        if(item.a > 0)
        {
            itemSprite.play();
        }
        return itemSprite;
    }
    static getFunction(id){
        if(id==undefined || id <= 0)
            return;
        var func = Items.itemList[id].u;
        switch(func)
        {
            case 1:
                return "hat";
            case 2:
                return "mouth";
            case 3:
                return "disguise";
            case 4:
                return "bomb";
            case 5:
                return "cleanspray";
            case 6:
                return "pushable";
            case 7:
                return "friendlyexplosive";
            case 8:
                return "fire";
            case 9:
                return "cleanspray";
            default:
                return "back";
        }
    }
    static isBackground(id){
	
        if(id==undefined || id <= 0)
            return;
        var func = Items.itemList[id].b;
        if(func == 1)
            return true;
        return false;
    }
    static getItemsTexture(id) //Dynamically fetch itemTexture
    {
        if(this.itemsTexture[id] == undefined)
        {
            this.itemsTexture[id] = window.PIXI.Texture.from(getGfx('item/'+id+'.png'));
        }
        
        return this.itemsTexture[id];
    };
}