import {getGfx, Items} from "../helpers.js";

export default class User extends window.PIXI.Container{
    shadowcolor = 0;
    id = 0;
    floor = window.PIXI.Sprite.from("/gfx/tile.png");
    dog = window.PIXI.Sprite.from("/gfx/stand.png");
    dogShadow = window.PIXI.Sprite.from("/gfx/stand_shadow.png");
    constructor(data, items){
        super();
        this.addChild(this.floor);
        this.addChild(this.dog);
        this.dog.tint = data.color;
        this.dog.x = 25;
        this.dog.y = 5;
        // this.dog.anchor.x = 1;
        // this.dog.scale.x = -1;

        this.addChild(this.dogShadow);
        this.dogShadow.tint = data.shadowcolor;
        this.dogShadow.x = 25;
        this.dogShadow.y = 5;
        // this.dogShadow.anchor.x = 1;
        // this.dogShadow.scale.x = -1;
        Items.processItemList(items);

        const wear = new Wear(data.hat);
        wear.x = 35;
        wear.y = 7;
        window.wear = wear;
        this.addChild(wear);

        this.y = 5;
    }
}
class Wear extends window.PIXI.Container{
    hat = new WearItem();
    mouth = new WearItem();
    back = new WearItem();
    constructor(hat, mouth, back){
        super();
        console.log("New wear", this, hat);
        this.hat.setItem(hat);
        this.mouth.setItem(mouth);
        this.back.setItem(back);

        this.addChild(this.hat);
        this.addChild(this.mouth);
        this.addChild(this.back);
    }
}

class WearItem extends window.PIXI.Container{
    setItem(id){
        this.removeChildren();
        if(id > 0)
            this.addChild(Items.getSprite(id));
    }
}




