import React from "react";
import "./HatList.css";
import {dataSort, getGfx} from "../helpers";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";

export default class HatList extends React.Component {
    state = {
        count: {},
        items: [],

        sortBy: "amount",
        type: "ASC",
        search: "",
    };

    componentWillMount()
    {
        this.parseData();
    }

    componentDidMount()
    {
        if(this.refs.search)
        {
            this.refs.search.focus();
        }
    }

    componentDidUpdate(prevProps, prevState)
    {
        if(prevProps.itemCount.length !== this.props.itemCount.length)
        {
            this.parseData();
        }
    }

    parseData()
    {
        const d = this.props.itemCount;
        const counter = d.reduce((arr, curr) => {
            arr[curr.hatID] = curr;
            return arr;
        }, []);
        this.setState({
            items: this.props.items.map((item, key) => {
                const itemCount = this.props.itemCount.find(d => d.hatID === item.id);
                return {
                    ...item,
                    amount: itemCount ? itemCount.amount : 0,
                    deadAmount: itemCount ? itemCount.deadAmount : 0,
                    lastSold: itemCount ? itemCount.lastSold : null,
                };
            }).filter(d => !!d.amount)
        })
    }

    setSort(key)
    {
        this.setState({
            sortBy: key,
            type: this.state.sortBy === key ? (this.state.type === "ASC" ? "DESC" : "ASC") : "ASC",
        });
    }

    render(){
        const rawData = dataSort(this.state.items, this.state.sortBy, this.state.type).filter(d => this.state.search.length === 0 || d.n.toLowerCase().includes(this.state.search));
        const data = rawData.filter((d, i) => i < 1000);
        return (<div className="items">
            <h2>Hatte</h2>
            <div className="d-flex justify-content-between align-items-center">
                <div>
                    Viser <b>{Math.min(rawData.length, 1000)}</b> ud af <b>{data.length}</b>.
                </div>
                <input ref="search" className="form-control w-25" placeholder="Søg" onKeyUp={(e) => this.setState({
                    search: e.target.value.toLowerCase(),
                })}/>
            </div>
            <div className="item" style={{fontWeight: "bolder", cursor: "pointer"}}>
                <div>
                   
                </div>
                <div className="name" onClick={() => this.setSort("id")}>
                    ID
                </div>
                <div className="name"  onClick={() => this.setSort("n")} style={{justifyContent: "center"}}>
                    Navn
                </div>
                <div className="count" onClick={() => this.setSort("count")} style={{justifyContent: "center"}}>
                    Antal i parken
                </div>
                <div className="date" style={{justifyContent: "center"}}>
                    Sidste salgsdato
                </div>
            </div>
            {
                data.map((item, key) => {
                    const IDs = item.g.split(",");
                    // console.log(item);
                    return (<Link to={`/view/${item.id}`} key={item.id}>
                            <div className="item">
                                <div className="img">
                                    {/* <img src={`gfx/item/${IDs[0]}.png`} />  */}
                                    <img src={getGfx(`item/${IDs[0]}.png`)} loading="lazy" /> 
                                </div>
                                <div className="name">
                                    {item.id}
                                </div>
                                <div className="name">
                                    {item.n}
                                </div>
                                <div className="count">
                                    {item.amount} {item.deadAmount > 0 && (<div style={{color: "red", marginLeft: "10px"}}>(-{item.deadAmount})</div>)}
                                </div>
                                <div className="date">
                                    {new Date(Number(item.lastSold || "ffs")).toLocaleDateString()}
                                </div>
                        </div>
                    </Link>);
                })
            }
        </div>);
    }
}