import React from "react";
import "./HatList.css";
import {dataSort, getGfx, shops as shopNames, download, cryptoRand} from "../helpers";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";

// import Restocks from "./Restock.js";

export default class HatRestocks extends React.Component {
    kobbels = [
        [], 
        [],
        [],
        [791, 752, 433, 282, 748, 543, 743], // Igloo
        [240, 274, 288, 547, 199, 200, 254, 413, 800, 690, 281, 635, 649, 650], // Klanen
        [47, 591, 146, 154, 220, 229, 287, 527, 632, 633, 257], // Banden
        [512, 790, 799, 284, 636, 1023, 750], // Sekten  | , 260 = JESUSBARN
        [290, 320, 751, 322, 742, 746, 170, 218, 219, 140, 16], // Stammen
        [374, 223, 228, 227, 225, 226, 573, 221, 777, 43, 81], // Flower
        [159, 513, 196, 216, 270, 269, 283, 853, 854, 134, 511], // Selskabet  549
        [570, 767, 1024, 608, 759, 687, 408, 407 ], // Losjen , 740
        [], // Henke
        [231, 822, 823, 242, 243, 248, 291, 1135], // Happy hippie
        [152, 499, 1104, 801], // Space 
    ];

    // groupPrices = {
    //     1: 800, // Common
    //     2: 1_500, // Semi-uncommon
    //     3: 4_000, // Uncommon
    //     4: 7_000, // Semi-rare
    //     5: 10_000, // Rare
    //     6: 15_000, // Semi super-rare
    //     7: 15_000, // Super rare
    //     8: 10_000, // Sentimental
    // };
    groupPrices = {
        1: [800, 1200], // Common
        2: [1_500, 2000], // Semi-uncommon
        3: [2000, 4_000], // Uncommon
        4: [5000, 7_000], // Semi-rare
        5: [6000, 10_000], // Rare
        6: [3000, 15_000], // Semi super-rare
        7: [3000, 15_000], // Super rare
        8: [3000, 10_000], // Sentimental
    };
    groupMins = {
        1: 10, // Common
        2: 10, // Semi-uncommon
        3: 8, // Uncommon
        4: 7, // Semi-rare
        5: 6, // Rare
        6: 6, // Semi super-rare
        7: 6, // Super rare
        8: 8, // Sentimental
    };

    round5(x)
    {
        return Math.ceil(x/5)*5;
    }

    randBetween(from, to)
    {
        return this.round5(cryptoRand() * (to - from)) + from;
    }

    getPrice(price, difference = 0.2) {
        const prob = this.randBetween(1 - difference, difference + 1);//1 - Math.abs((cryptoRand() * (difference * 2)) - difference);

        return this.round5(price * prob);
    }

    render()
    {
        const blacklistKobbel = this.kobbels.flat();
        const tempBlackList = [1161, 1174,1108,1063,1062];
        const blacklist = [
            ...blacklistKobbel,
            ...tempBlackList,
        ];
        console.log("Blacklist", blacklist);

        const hatCount = this.props.items.map(d => {
            const count = (this.props?.hatCount ?? []).find(hat => hat.hatID === d.id);
            if(count)
            {
                d = {
                    ...d,
                    ...count,
                };
            }else{
                d.count = 0;
            }
            return d;
        })
        console.log("Hehe", hatCount);
        const items = hatCount.filter(d => !blacklist.includes(d.id))
        // .sort((a, b) => Number(a.lastSold) - Number(b.lastSold))
        // .filter(item => {
        //     const rarity = this.props.rarities.find(d => d.hatID === item.id);
        //     return !!rarity || !!this.groupPrices[rarity.rarity];
        // })
        .sort((a, b) => Number(a.count) - Number(b.count));
        
        console.log(this.props, items);
        let gen = amount => {
            let ret = [];
            for(let i = 0; i < amount; i++)
            {  
                const item = items[i];
                if(!item)
                {
                    continue;
                }
                const realItem = this.props.items[item.id];
                const rarity = this.props.rarities.find(d => d.hatID === item.id);
                const count = (this.props?.itemCount ?? []).find(hat => hat.hatID === item.id);
                if(!rarity || !this.groupPrices[rarity.rarity])
                {
                    amount++;
                    continue;
                }
                if(count)
                {
                    // console.log("Huh", count, item, this.props);
                }
                const [fromPrice, toPrice] = this.groupPrices[rarity.rarity];
                ret.push({
                    // hatID: item.id,
                    // rarity: rarity.rarity,
                    hatID: item.id,
                    currCount: count?.amount ?? 0,
                    name: realItem.n,
                    price: this.randBetween(fromPrice, toPrice),//this.getPrice(this.groupPrices[rarity.rarity]),
                    amount: this.groupMins[rarity.rarity],
                });
            }

            return ret;
        };

        console.log(this.props.rarities.length > 0, items.length > 0);
        if(this.props.rarities.length > 0 && items.length > 0)
        {
            // console.clear();
            console.log("Staritng..");
            console.log("Hmm", gen(550).sort((a, b) => Number(a.currCount) - Number(b.currCount)));
        }
        return null;
    }
}