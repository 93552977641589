import React from "react";
import "./HatList.css";
import {dataSort} from "../helpers";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";

export default class HatCalulator extends React.Component {
    state = {
        count: {},
        items: [],

        sortBy: "count",
        type: "ASC",
        search: "",

        price: 0,
        amount: 0,
        popularity: 1,
    };

    componentDidUpdate(prevProps, prevState)
    {
        if(JSON.stringify(prevState.prevState) === JSON.stringify(this.state) || prevProps.itemCount.length !== this.props.itemCount.length)// !== this.props.itemCount.length)
        {
            this.parseData();
        }
    }

    parseData()
    {
        const d = this.props.itemCount;
        const counter = d.reduce((arr, curr) => {
            arr[curr.hatID] = curr.amount;
            return arr;
        }, []);
        this.setState({
            items: this.props.items.map((item, key) => {
                const itemCount = this.props.itemCount.find(d => d.hatID === item.id);
                return {
                    ...item,
                    count: counter[item.id],
                    lastSold: itemCount ? itemCount.lastSold : null,
                };
            }).filter(d => !!d.count)
        })

    }

    estimateRarity(dermand, rarity)
    {
        const normalized = dermand * rarity;
        console.log("Dermand:", dermand, "Rarity:", rarity, "=", normalized);
        if(normalized > 1.34)
        {
            return 3;
        }else if(normalized > 1.22)
        {
            return 2;
        }else if(normalized > 1)
        {
            return 1;
        }
        return 0; // Shite
    }


    render(){
        const rarityNames = ["Common", "Uncommon", "Rare", "Super rare"];
        const weekDate = new Date();
        weekDate.setDate(weekDate.getDate() - 14);
        
        const activeDogs = this.props.users.filter(user => Number(user.lastLogin) >= weekDate.getTime() && user.kb > 0);
        const targetDogs = activeDogs.filter(user => user.kb >= this.state.price);
        
        const items = this.state.items.filter(d => d.count > 0);
        const targetAmount = items.filter(d => d.count >= this.state.amount);
        // console.log("Tprops", this.props, activeDogs, items);
        // const activeDogs;

        // console.log(items, targetAmount);

        const pricePercentage = (targetDogs.length / activeDogs.length * 100);
        const amountPercentage = (1 - (targetAmount.length / items.length)) * items.length;

        const dermandPercent = Math.max(1 - (this.state.amount / targetDogs.length), 0);// Percentage of dogs who can afford it
        // console.log(dermandPercent);
        const rarity = this.estimateRarity(dermandPercent, this.state.popularity);
        return (<div className="items">
            <h2>Pris bergener</h2>
            <form>
                <label>Salgs pris</label><br/>
                <input type="number" min="0" defaultValue="0" onChange={(e) => this.setState({price: Number(e.target.value)})} /><br/>
                <label>Antal</label><br/>
                <input type="number" min="0" defaultValue="0" onChange={(e) => this.setState({amount: Number(e.target.value)})} /><br/>
                <div style={{display: "none"}}>
                    <label>Popularitet</label><br/>
                    <div style={{display: "flex", justifyContent: "space-between", width: "300px"}}>
                        <div>
                            <label for="extreme">Ekstremt</label> <input id="extreme" value="1.9" onChange={(e) => this.setState({popularity: Number(e.target.value)})} type="radio" name="dermand"/> 
                        </div>
                        <div>
                            <label for="high">Høj</label> <input id="high" value="1.55" onChange={(e) => this.setState({popularity: Number(e.target.value)})} type="radio" name="dermand"/> 
                        </div>
                        <div>
                            <label for="middle">Middel</label> <input id="middle" value="1.25" onChange={(e) => this.setState({popularity: Number(e.target.value)})} type="radio" name="dermand"/> 
                        </div>
                        <div>
                            <label for="low">Lav</label> <input id="low" value="1" onChange={(e) => this.setState({popularity: Number(e.target.value)})}type="radio" name="dermand" defaultChecked={true}/> 
                        </div>
                    </div>
                </div>
            </form><br/>
            <b>{targetDogs.length}</b>({(Number.isNaN(pricePercentage) ? 100 : pricePercentage).toFixed(2)}%) af aktive hunde, med kødben vil have råd til den.<br/>
            Den vil ligge i <b>top {Math.ceil(amountPercentage || 0) + 1}</b> af antallet af hatte.<br/>

            {/* <h2>{rarityNames[rarity]}</h2> */}
        </div>);
    }
}