import React from "react";
import "./HatList.css";
import {shops, formatNumber, getGfx, getType} from "../helpers";

import {Link} from "react-router-dom";
import { Chart } from "react-google-charts";

export default class HatViewer extends React.Component {
    initialState = {
        count: {},
        items: [],

        userData: [],
        shopHistory: [],
        users: [],
        item: false,

        drawChart: false,

        newHistory: [],
    };
    state = {...this.initialState};

    componentDidMount()
    {
        if(this.props.users.length > 0)
        {
            this.parseData();
        }
    }

    componentDidUpdate(prevProps, prevState)
    {
        if(prevProps.itemCount.length !== this.props.itemCount.length || prevProps.users.length !== this.props.users.length)
        {
            this.setState({...this.initialState});
            this.parseData();
        }
    }

    async parseData()
    {
        const userData = this.props.users;
        // const d = this.props.itemCount;
        const shop = await this.props.api("shopHistory/" + this.props.match.params.id);

        this.setState({
            userData: userData.reduce((obj, curr) => {
                obj[curr.id] = curr;
                return obj;
            }, {}),
            shopHistory: shop.data,
        });


        const d = await this.props.api("itemHistory/" + this.props.match.params.id);
        if(!d)
        {
            return;
        }
        // const [userData, d] = await Promise.all([
        //     api("users"),
        //     api("itemCount"),
        // ]).then(d => d);

        // const userData = await api("users");
        
        const realUsersTemp = d.reduce((obj, curr) => {
            if(!obj[curr.userID])
            {
                obj[curr.userID] = [];
            }
            obj[curr.userID].push(curr);
            return obj;
        }, {});
        const realUsers = Object.keys(realUsersTemp).map(userID => {
            return realUsersTemp[userID].sort((a, b) => Number(b.unix) - Number(a.unix)).reduce((arr, curr) => {
                
                if(arr.length === 0)
                {
                    arr.push([]);
                }

                const target = arr.length - 1;
                if(arr[target].length === 0 || arr[target][0].unix === curr.unix)
                {
                    arr[target].push(curr);
                }else{
                    arr.push([curr]);
                }

                return arr;
            }, []);
        });

        this.setState({
            users: realUsers,
        });
        
       requestAnimationFrame(async () => {
            const itemCount = Object.keys(realUsers).map(id => realUsers[id]).reduce((counter, arr) => {
                const curr = arr[0];
                curr.forEach(item => {
                    if(!counter[item.hatID])
                    {
                        counter[item.hatID] = 0;
                    }
                    counter[item.hatID]++;

                });
                return counter;
            }, []);
            

            // const realItems = Object.keys(realUsers).map(key => realUsers[key][0]).reduce((arr, curr) => [
            //     ...arr,
            //     ...curr
            // ], []);
            // const realCount = realItems.reduce((counter, curr) =>{
            //     if(!counter[curr.hatID])
            //     {
            //         counter[curr.hatID] = 0;
            //     }
            //     counter[curr.hatID]++;
            //     return counter;
            // }, {});
            
            
            const ddd = await this.props.api("betterHistory/" + this.props.match.params.id);
            this.setState({
                items: this.props.items.map(item => ({
                    ...item,
                    count: itemCount[item.id]
                })).filter(d => !!d.count),
                newHistory: ddd.data,
                
            });

            requestAnimationFrame(() => this.mapItemAmount());
       });
    }

    getTimelineData()
    {
        const data = this.state.newHistory;
        let ret = [];
        console.log("GImme history", data);
        for(let i = 0; i < data.length; i++)
        {
            const users = data[i];
            for(let j = 0; j < users.length; j++)
            {
                const user = users[j];
                let start = Number(user.unix);
                
                let end = i < (data.length - 1) ? Number(data[i + 1][0].unix) : Date.now();
                ret.push([
                    this.state.userData[user.userID] ? this.state.userData[user.userID].name : "",
                    new Date(Math.min(start, end)),
                    new Date(Math.max(start, end)),
                ]);
            } 
        }

        // console.log("Hmm", ret);
        // ret = [...ret].map(time => {
        //     const myTimes = ret.filter(d => d[0] === time[0]);
        //     const overlap = myTimes.filter(d => !d.overlapped && d[1].getTime() >= time[1].getTime() && d[2].getTime() < time[2].getTime());
        //     // console.log("Has overlap??", overlap, myTimes);
        //     for(let i = 0; i < overlap.length; i++)
        //     {
        //         const curr = overlap[i];
        //         if(curr[2].getTime() > time[2].getTime())
        //         {
        //             time[2] = curr[2];
        //         }
        //     }
        //     if(overlap.length > 0)
        //     {
        //         console.log("Updated overlap!", overlap);
        //         time.overlapped = true;
        //     }
        //     return time;
        // });

        // ret = ret.filter(time => {
        //     const myTimes = ret.filter(d => d[0] === time[0]);
        //     const diffMe = time[2].getTime() - time[1].getTime();
        //     const overlap = myTimes.some(d => {
        //         const diff = d[2].getTime() - d[1].getTime();
        //         return d[1].getTime() >= time[1].getTime() && d[2].getTime() <= time[2].getTime() && diff < diffMe;
        //     });
        //     console.log("Has overlap??", overlap, myTimes);
        //     return !overlap;
        // });

        // console.log(ret);
        // let overlaps = {

        // };
        // let tempRet = [...ret];

        // for(let i = 0; i < ret.length; i++)
        // {
        //     const curr = ret[i];
        //     if(!overlaps[curr[0]])
        //     {
        //         overlaps[curr[0]] = 0;
        //     }

        //     if(curr.overlap)
        //     {
        //         if(overlaps[curr[0]] > 0)
        //         {
        //             tempRet = tempRet.filter(d => d[0] === curr[0] && d.overlap);
        //         }
        //         overlaps[curr[0]]++;
        //     }
        // }


        // timeline = timeline.map(line => {

        //     return line.filter(usr => {
        //         const withinSomeone = timeline.some(line => line.some(d => Number(d.unix) > Number(usr.unix) && Number(d.unix) < Number(usr.unix)));
        //     });
        // });

        //Glue stuff together
        // for(let i = 0; i < ret.length; i++)
        // {
        //     const curr = ret[i];

        //     let glueI = i;
        //     const gluePoints = [];
        //     let glueing = true;
        //     while(glueing)
        //     {
        //         const next = ret.findIndex((d, j) => j > glueI && d[0] === curr[0]);
        //         if(next !== -1)
        //         {
        //             const temp = ret[next];
        //             const tempCurr = ret[glueI];
        //             // console.log("Compare", temp, tempCurr);
        //             if(tempCurr[2].getTime() === temp[1].getTime())
        //             {
        //                 gluePoints.push(next);
        //                 glueI = next;
        //             }else{
        //                 glueing = false;
        //             }
        //         }else{
        //             glueing = false;
        //         }
        //     }
        //     if(gluePoints.length > 0)
        //     {
        //         console.log(ret[gluePoints.length - 1][2]);
        //         ret[i][2] = ret[gluePoints[gluePoints.length - 1]][2];
        //         ret = ret.filter((_, i) => !gluePoints.includes(i));
        //         console.log("GLuer", gluePoints);
        //     }
        // }
        // console.log(ret);
        return ret;
    }

    getTimelineData2()
    {
        const HP_start = new Date("Nov 13 2020").getTime();
        const itemID = Number(this.props.match.params.id);
        let data = this.state.users.map(d => {
            return d.map(d => d.filter(item => item.hatID == itemID).flat());            
        }).flat().flat().sort((a, b) => Number(a.unix) - Number(b.unix));
        console.log("Input", data);


        const connectPoints = (i, data) => {
            const curr = data[i];
            const nextMe = data.findIndex((d, key) => key > i && d.unix !== curr.unix && d.userID === curr.userID);
            // console.log("Me", nextMe);
            return nextMe > -1 ? connectPoints(nextMe, data).filter((_, i) => i !== nextMe) : data;
        };

        const findConnected = (i, data) => {
            const curr = data[i];
            let ret = [curr];
            const nextConnected = data.findIndex((d, key) => key > i && d.unix !== curr.unix && d.userID === curr.userID);
            if(nextConnected !== -1)
            {
                ret = [
                    ...ret,
                    ...findConnected(nextConnected, data),
                ]
            }
            return ret;
        };

        const ret = [];

        const groups = data.reduce((arr, curr) => {
            const key = arr.length - 1;
            if(arr[key].length > 0 && arr[key][arr[key].length - 1].unix !== curr.unix)
            {
                arr.push([curr]);
            }else{
                arr[key].push(curr);
            }
            return arr;
        }, [[]]).filter(d => d.length > 0);

        const itemCount = Math.max(...groups.map(d => d.length));
        const peak = groups.map(d => d.length).findIndex(d => d === itemCount);
        // console.log("groups", groups, itemCount);
        let didConnect = [];
        if(data.length > 0)
        {
            const FIRST_DATE = data[0].unix;

            for(let i = 0; i < data.length; i++)
            {
                const connected = findConnected(i, data);
                // data = connectPoints(i, data);
                const curr = data[i];
                // console.log("Connected", connected, curr);
                if(didConnect.includes(curr.userID))
                {
                    continue;
                }
                const start = Number(curr.unix);// === FIRST_DATE ? HP_stFIRST_DATEart : Number(curr.unix);
                const nextOther = data.find((d, key) => key > i && d.unix !== curr.unix);
                // console.log(curr, nextOther);
                let end = !nextOther || i >= (data.length - itemCount) ? Date.now() : Number(nextOther.unix);
                if(curr.userID == 233)
                {
                    console.log("HotDog", curr, connected, nextOther);
                }
                if(connected.length > 1)
                {
                    // console.log(nextOther, i >= (data.length - itemCount))
                    end = Number(connected[connected.length - 1].unix);
                    didConnect.push(curr.userID);
                }
                if(this.state.userData[curr.userID])
                {
                    ret.push([
                        this.state.userData[curr.userID].name,
                        new Date(start),
                        new Date(end),
                    ]);
                }
            }
        }

        for(let i = 0; i < ret.length; i++)
        {
            const curr = ret[i];

            const nextOther = ret.find((d, j) => j > i && d[1] > curr[2]);
            if(nextOther)
            {
                // console.log("Gnallis", curr, nextOther);
                curr[2] = nextOther[1];
            }

        }
       
        return ret;
    }


    mapItemAmount()
    {
        const itemID = Number(this.props.match.params.id);

        let data = this.state.users.map(d => {
            return d.map(d => d.filter(item => item.hatID == itemID).flat());            
        }).flat().flat().sort((a, b) => Number(a.unix) - Number(b.unix));
        const groups = data.reduce((arr, curr) => {
            const key = arr.length - 1;
            if(arr[key].length > 0 && arr[key][arr[key].length - 1].unix !== curr.unix)
            {
                arr.push([curr]);
            }else{
                arr[key].push(curr);
            }
            return arr;
        }, [[]]).filter(d => d.length > 0);

        // const ctx = this.refs.amountCanvas.getContext('2d');
		// this.kbChart = new window.Chart(ctx, {
		// 	type: 'line',
		// 	data: {
		// 		labels: history.map(d => new Date(Number(d.unix)).toLocaleString()),
		// 		datasets: [{
		// 			label: 'KB',
		// 			backgroundColor: "#f30707",
		// 			borderColor: "#ad2727",
		// 			data: history.map(d => d.amount),
		// 			fill: false,
		// 		}]
		// 	},
		// 	options: {
		// 		responsive: false,
		// 		title: {
		// 			display: true,
		// 			text: 'KB historik'
		// 		},
		// 		tooltips: {
		// 			mode: 'index',
		// 			intersect: false,
		// 		},
		// 		hover: {
		// 			mode: 'nearest',
		// 			intersect: true
		// 		},
		// 		scales: {
		// 			xAxes: [{
		// 				display: true,
		// 				scaleLabel: {
		// 					display: true,
		// 					labelString: 'Tidspunkt'
		// 				}
		// 			}],
		// 			yAxes: [{
		// 				display: true,
		// 				scaleLabel: {
		// 					display: true,
		// 					labelString: 'Value'
		// 				}
		// 			}]
		// 		}
		// 	}
		// });
    }

    
    render(){
        const target = this.props.match.params.id;
        const item = this.props.items[this.props.match.params.id];
        // console.log("Users", this.state.users);
        const owners = this.state.users.filter(d => {
            return d[0].some(item => item.hatID == target);            
        });
        const allOwners = this.state.drawChart ? this.getTimelineData() : [];
        // console.log("Owners", allOwners, owners);

        // if(!item)
        // {
        //     return (<div>
        //             Loading..
        //     </div>);
        // }
        // console.log("Owners", owners);
        const IDs = ((item ? item.g : "").split(","));
        return (<div className="info">
            {
                item && <div className="details">
                    <div className="img">
                        <img src={getGfx(`item/${IDs[0]}.png`)} loading="lazy" /> 
                    </div>
                    <div className="name">
                        {item.n}
                    </div>
                    <div className="name">
                        -
                    </div>
                    <div className="count">
                        {owners.length} ejere
                    </div>
                    <div className="name">
                        -
                    </div>
                    <div className="name">
                        {item.d}
                    </div>
                    <div className="name">
                        -
                    </div>
                    <div className="center">
                        {getType(item.u)}
                    </div>
                    {/* <div className="date">
                        0/0/0000
                    </div> */}
                </div>
            }

            <div>
                <h2>Ejere</h2>
                <div>
                    <div>
                        {
                            owners.map(user => {
                                const userData = this.state.userData[user[0][0].userID];
                                const amount = user[0].filter(d => d.hatID === Number(target)).length;
                                return (<div>
                                    <Link to={`/user/${user[0][0].userID}`} style={userData?.lifepoints < 0 ? {color: "red"} : {}}>
                                        {amount} x{` `}
                                        {userData?.lifepoints < 0 && "💀"}
                                        {userData?.name} [{userData?.username}]
                                        {userData?.lifepoints < 0 && "💀"}
                                    </Link>
                                </div>);
                            })
                        }
                    </div>
                </div>
            </div>
            <div className="mt-3 mb-3">
                <h2>Salgs historik</h2>
                <table style={{width: "300px"}}>
                        <tr style={{fontWeight: "bold"}}>
                            <td>
                                Shop
                            </td>
                            <td>
                                Pris
                            </td>
                            <td>
                                Dato
                            </td>
                            <td>
                                
                            </td>
                        </tr>
                        {
                            this.state.shopHistory.map((shop, key) => <tr key={key} onClick={() => this.props.history.push(`/shop/` + shop.shop)} style={{cursor: "pointer"}}>
                                <td>
                                    {shops[shop.shop] || "Ukendt shop"}
                                </td>
                                <td>
                                    {formatNumber(shop.price)}kb
                                </td>
                                <td>
                                    {new Date(Number(shop.unix)).toLocaleDateString()}
                                </td>
                                <td>
                                    {shop.inStore === 1 && <b>I butik nu</b>}
                                </td>
                            </tr>)
                        }
                </table>
            </div>

            
            {/* <h2>Antal</h2>
            <canvas ref="amountCanvas"></canvas> */}
            <h2>Tidslinje</h2>
            {
                !this.state.drawChart && <button onClick={() => this.setState({drawChart: true})}>Vis tidslinje</button>
            }
            {
                allOwners.length > 0 && this.state.drawChart && 
                    <Chart
                        width={'100%'}
                        key={allOwners.length}
                        height={(allOwners.length * 41) + 50}
                        chartType="Timeline"
                        loader={<div>Loading..</div>}
                        data={[
                            [
                                { type: 'string', id: 'President' },
                                { type: 'date', id: 'Start' },
                                { type: 'date', id: 'End' },
                            ],
                            ...allOwners,
                            // ['Washington', new Date(1789, 3, 30), new Date(1797, 2, 4)],
                            // ['Adams', new Date(1797, 2, 4), new Date(1801, 2, 4)],
                            // ['Jefferson', new Date(1801, 2, 4), new Date(1809, 2, 4)],
                        ]}
                        options={{
                            showRowNumber: true,
                        }}
                        rootProps={{ 'data-testid': '1' }}

                        chartEvents={[
                            {
                              eventName: 'select',
                              callback: ({ chartWrapper }) => {
                                const chart = chartWrapper.getChart();
                                const selection = chart.getSelection();
                                const target = allOwners[selection[0].row];
                                if(target)
                                {
                                    console.log("Target", target, this.state);
                                    const usr = Object.keys(this.state.userData).find(id => this.state.userData[id].name === target[0]);
                                    if(usr)
                                    {
                                        console.log("Target", this.state.userData[usr], this.props.history);
                                        this.props.history.push("/user/" + usr);
                                    }
                                }
                              },
                            },
                          ]}
                    />
            }
            
        </div>);
       
    }
}